<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-md-between justify-content-end mb-2 mx-auto gap-2">
      <div class="col-12 p-0 col-md-auto d-inline-flex">
        <h5 class="my-auto">Data Siswa</h5>
      </div>
      <div class="col-auto p-0">
        <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
          data-bs-target="#tambahData">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
          </svg>Tambahkan Data Siswa
        </button>
      </div>
      <div class="col-auto p-0">
        <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Cari . . ."
          v-model="key" @keyup="search" @search="search">
      </div>
    </div>
    <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
      <div class="col-auto p-0">
        <download-csv class="btn btn-sm btn-outline-success hstack gap-1 shadow-sm" :data="templateSiswa"
          :fields="['nis', 'nama', 'jenis_kelamin', 'agama_id', 'kontak', 'status_siswa_id', 'va']"
          name="template_data_siswa.csv"><svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor"
            class="bi bi-filetype-csv" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
          </svg>Unduh Template Data Siswa
        </download-csv>
      </div>
      <div class="col-auto p-0">
        <button type="button" class="btn btn-sm btn-outline-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
          data-bs-target="#unggahData">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-upload"
            viewBox="0 0 16 16">
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path
              d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
          </svg>Unggah Data Siswa (.csv)
        </button>
      </div>
    </div>
    <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
      <div class="col-auto p-0">
        <download-csv class="btn btn-sm btn-outline-primary hstack gap-1 shadow-sm" :data="siswaNisKosong"
          :fields="['siswa_id', 'no_reg', 'nama', 'nis', 'va']" name="data_siswa_nis_kosong.csv"><svg
            xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-filetype-csv"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
          </svg>Unduh Daftar Nama Siswa
        </download-csv>
        <span class="text-muted fst-italic small text-wrap">&#9432; siswa yang belum memiliki NIS</span>
      </div>
      <div class="col-auto p-0">
        <button type="button" class="btn btn-sm btn-outline-dark hstack gap-1 shadow-sm" data-bs-toggle="modal"
          data-bs-target="#unggahDataNis">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-upload"
            viewBox="0 0 16 16">
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path
              d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
          </svg>Unggah Data NIS (.csv)
        </button>
      </div>
    </div>
    <div v-if="tercentang.length" class="mb-2 text-end">
      <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
    </div>
    <div class="table-responsive shadow vl-parent" ref="table">
      <table class="table table-sm mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>#</th>
            <th>NIS</th>
            <th class="position-sticky start-0">Nama</th>
            <th>
              <div class="hstack justify-content-between">
                <span class="col-auto my-auto">Status</span>
                <button type="button"
                  class="btn btn-sm btn-outline-secondary d-flex col-auto border-0 dropdown-toggle-split"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <svg v-if="statusSiswa" xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                    class="bi bi-funnel-fill" viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-funnel"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                  </svg>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="statusSiswa">
                    <div class="dropdown-header hstack justify-content-between">
                      <span class="">{{ statusSiswa.singkatan }}</span>
                      <button class="btn-close" @click="statusSiswa = null"></button>
                    </div>
                  </li>
                  <li v-for="s in listStatus" :key="s" :value="s">
                    <button class="dropdown-item" @click="statusSiswa = s">
                      {{ s.singkatan }}
                    </button>
                  </li>
                </ul>
              </div>
            </th>
            <th>
              <div class="hstack justify-content-between">
                <span class="col-auto my-auto">Agama</span>
                <button class="btn btn-sm btn-outline-secondary d-flex col-auto border-0" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <svg v-if="agama" xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                    class="bi bi-funnel-fill" viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-funnel"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                  </svg>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="agama">
                    <div class="dropdown-header hstack justify-content-between">
                      <span class="">{{ agama.nama }}</span>
                      <button class="btn-close" @click="agama = null"></button>
                    </div>
                  </li>
                  <li v-for="a in listAgama" :key="a">
                    <button class="dropdown-item" @click="agama = a">
                      {{ a.nama }}
                    </button>
                  </li>
                </ul>
              </div>
            </th>
            <th>
              <div class="hstack justify-content-between">
                <span class="col-auto my-auto">JK</span>
                <button class="btn btn-sm btn-outline-secondary d-flex col-auto border-0" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <svg v-if="jenisKelamin" xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                    class="bi bi-funnel-fill" viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-funnel"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                  </svg>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="jenisKelamin">
                    <div class="dropdown-header hstack justify-content-between">
                      <span class="">{{ jenisKelamin.kode }}</span>
                      <button class="btn-close" @click="jenisKelamin = null"></button>
                    </div>
                  </li>
                  <li v-for="j in listJk" :key="j">
                    <button class="dropdown-item" @click="jenisKelamin = j">
                      {{ j.kode }}
                    </button>
                  </li>
                </ul>
              </div>
            </th>
            <th>Kontak</th>
            <th>VA</th>
            <th>Foto</th>
            <th>
              <div class="d-flex justify-content-between">
                Tindakan
                <input class="form-check-input" type="checkbox" v-model="semuaTercentang" @click="centangSemua">
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataSiswa.length">
          <tr v-for="d, index in dataSiswa" :key="d">
            <td>{{ index+=1 + pageOffset }}</td>
            <td>{{ d.nis || '-' }}</td>
            <td class="position-sticky start-0">{{ d.nama }}</td>
            <td>{{ d.singkatan }}</td>
            <td>{{ d.nama_agama }}</td>
            <td>{{ d.jenis_kelamin }}</td>
            <td>{{ d.kontak || '-' }}</td>
            <td>{{ d.va || '-' }}</td>
            <td>{{ d.foto || '-' }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)" data-bs-toggle="modal"
                    data-bs-target="#editData" v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
                <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatu(d)">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="10">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span v-if="dataSiswa.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataSiswa.length }} dari total {{ count }} data
    </span>
  </div>
  <div v-if="dataSiswa.length" class="card-footer">
    <div class="row row-cols-auto justify-content-sm-between justify-content-center">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
              @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCount " :key="p">
            <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
            (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
              @click="pageCurrent = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
              @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Siswa</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">NIS</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Induk Siswa"
                  v-model="formCreate.nis" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Lengkap Siswa"
                  v-model="formCreate.nama" required>
              </div>
            </div>
            <fieldset class="row mb-3 pt-0">
              <legend class="col-sm-3 col-form-label col-form-label-sm pt-0">Jenis Kelamin</legend>
              <div class="col-sm-9">
                <div class="form-check form-check-inline" v-for="jk in listJk" :key="jk">
                  <input class="form-check-input" name="jenis_kelamin" :value="jk.kode" type="radio" :id="jk.kode"
                    v-model="formCreate.jenis_kelamin" required>
                  <label class="form-check-label" :for="jk.kode">{{ jk.nama }}</label>
                </div>
              </div>
            </fieldset>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Agama</label>
              <div class="col-sm-9">
                <v-select :options="listAgama" label="nama" :placeholder="'Pilih Agama'" :selectOnTab="true"
                  :clearable="false" v-model="formCreate.agama">
                  <template #no-options>
                    Agama tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formCreate.agama" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kontak</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Telepon Siswa"
                  v-model="formCreate.kontak" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">No. VA</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Virtual Account SPP"
                  v-model="formCreate.va" required>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Status</label>
              <div class="col-sm-9">
                <v-select :options="listStatus" label="keterangan" :placeholder="'Pilih Status Siswa'"
                  :selectOnTab="true" :clearable="false" v-model="formCreate.status_siswa">
                  <template #no-options>
                    Status siswa tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formCreate.status_siswa" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Siswa</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">NIS</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Induk Siswa"
                  v-model="formEdit.nis" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Lengkap Siswa"
                  v-model="formEdit.nama" required>
              </div>
            </div>
            <fieldset class="row mb-3 pt-0">
              <legend class="col-sm-3 col-form-label col-form-label-sm pt-0">Jenis Kelamin</legend>
              <div class="col-sm-9">
                <div class="form-check form-check-inline" v-for="jk in listJk" :key="jk">
                  <input class="form-check-input" name="jenis_kelamin" :value="jk.kode" type="radio" :id="jk.kode"
                    v-model="formEdit.jenis_kelamin" required>
                  <label class="form-check-label" :for="jk.kode">{{ jk.nama }}</label>
                </div>
              </div>
            </fieldset>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Agama</label>
              <div class="col-sm-9">
                <v-select :options="listAgama" label="nama" :placeholder="'Pilih Agama'" :selectOnTab="true"
                  :clearable="false" v-model="formEdit.agama">
                  <template #no-options>
                    Agama tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.agama" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kontak</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Telepon Siswa"
                  v-model="formEdit.kontak" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">No. VA</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nomor Virtual Account SPP"
                  v-model="formEdit.va" required>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Status</label>
              <div class="col-sm-9">
                <v-select :options="listStatus" label="keterangan" :placeholder="'Pilih Status Siswa'"
                  :selectOnTab="true" :clearable="false" v-model="formEdit.status_siswa">
                  <template #no-options>
                    Status siswa tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formEdit.status_siswa" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="unggahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formUpload">
        <form @submit.prevent="unggahFile">
          <div class="modal-header">
            <legend class="modal-title">Unggah Data Siswa</legend>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">File CSV</label>
              <div class="col-sm-9">
                <input type="file" accept=".csv" class="form-control form-control-sm" placeholder="Pilih File CSV"
                  required oninvalid="this.setCustomValidity('Silakan pilih file yang akan diunggah!')"
                  oninput="this.setCustomValidity('')" ref="fileInput" @change="handleInputFile">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" id="tombol-unggah" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="unggahDataNis" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formUpload">
        <form @submit.prevent="unggahFileNis">
          <div class="modal-header">
            <legend class="modal-title">Unggah Data NIS</legend>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">File CSV</label>
              <div class="col-sm-9">
                <input type="file" accept=".csv" class="form-control form-control-sm" placeholder="Pilih File CSV"
                  required oninvalid="this.setCustomValidity('Silakan pilih file yang akan diunggah!')"
                  oninput="this.setCustomValidity('')" ref="fileInputNis" @change="handleInputFileNis">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" id="tombol-unggah-nis" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import { debounce } from 'lodash';
export default {
  name: 'DataSiswa',
  data() {
    return {
      insertListener: () => {
        this.getDataSiswa();
      },
      updateListener: (d) => {
        if (Object(this.formEdit).siswa_id == d) {
          document.getElementById('tutupFormEdit').click();
        }
        this.getDataSiswa();
        this.getSiswaNisKosong();
      },
      deleteListener: (d) => {
        if (Number.isInteger(d)) {
          if (Object(this.formEdit).siswa_id == d) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.pageCurrent == this.pageCount && this.dataSiswa.length == 1) {
            if (this.pageCurrent == 1) {
              this.getDataSiswa();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getDataSiswa();
          }
        }
        if (d instanceof Array) {
          if (d.includes(Object(this.formEdit).siswa_id)) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.pageCurrent == this.pageCount && this.dataSiswa.length <= d.length) {
            if (this.pageCurrent == 1) {
              this.getDataSiswa();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getDataSiswa();
          }
        }
      },
      insertOrDeleteStatusListener: () => {
        this.getListStatus();
      },
      updateStatusListener: (d) => {
        this.getListStatus();
        if (this.dataSiswa.some((s) => {
          return s.status_siswa_id == d
        })) {
          this.getDataSiswa();
        }
      },
      insertOrDeleteAgamaListener: () => {
        this.getListAgama();
      },
      updateAgamaListener: (d) => {
        this.getListAgama();
        if (this.dataSiswa.some((s) => {
          return s.agama_id == d
        })) {
          this.getDataSiswa();
        }
      },
      dataSiswa: [],
      siswaNisKosong: [],
      listJk: [{
        kode: 'L',
        nama: 'Laki-laki'
      },
      {
        kode: 'P',
        nama: 'Perempuan'
      }],
      listAgama: [],
      listStatus: [],
      key: '',
      jenisKelamin: null,
      agama: null,
      statusSiswa: null,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      formCreate: {
        nis: null,
        nama: null,
        jenis_kelamin: null,
        agama: null,
        kontak: null,
        status_siswa: null,
        va: null
      },
      templateSiswa: [{
        nis: '12345/012.01',
        nama: 'Nama Lengkap Siswa',
        jenis_kelamin: 'opsi: L/P',
        agama_id: 'referensi ID:',
        kontak: '081234xxxxxx',
        status_siswa_id: 'referensi ID:',
        va: '12345',
      }],
      selectedData: {},
      formEdit: {},
      tercentang: [],
      semuaTercentang: false,
      selectedFile: null,
      selectedFileNis: null,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataSiswa();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataSiswa();
      } else {
        this.pageCurrent = 1;
      }
    },
    jenisKelamin() {
      if (this.pageCurrent == 1) {
        this.getDataSiswa();
      } else {
        this.pageCurrent = 1;
      }
    },
    agama() {
      if (this.pageCurrent == 1) {
        this.getDataSiswa();
      } else {
        this.pageCurrent = 1;
      }
    },
    statusSiswa() {
      if (this.pageCurrent == 1) {
        this.getDataSiswa();
      } else {
        this.pageCurrent = 1;
      }
    }
  },
  mounted() {
    this.getDataSiswa();
    this.getListAgama();
    this.getListStatus();
    this.getSiswaNisKosong();
    WebSocketService.on("inserted-siswa", this.insertListener);
    WebSocketService.on("updated-siswa", this.updateListener);
    WebSocketService.on("deleted-siswa", this.deleteListener);
    WebSocketService.on("inserted-status-siswa", this.insertOrDeleteStatusListener);
    WebSocketService.on("updated-status-siswa", this.updateStatusListener);
    WebSocketService.on("deleted-status-siswa", this.insertOrDeleteStatusListener);
    WebSocketService.on("inserted-agama", this.insertOrDeleteAgamaListener);
    WebSocketService.on("updated-agama", this.updateAgamaListener);
    WebSocketService.on("deleted-agama", this.insertOrDeleteAgamaListener);
    WebSocketService.on("graduated-anggota-rombel", this.insertListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-siswa", this.insertListener);
    WebSocketService.off("updated-siswa", this.updateListener);
    WebSocketService.off("deleted-siswa", this.deleteListener);
    WebSocketService.off("inserted-status-siswa", this.insertOrDeleteStatusListener);
    WebSocketService.off("updated-status-siswa", this.updateStatusListener);
    WebSocketService.off("deleted-status-siswa", this.insertOrDeleteStatusListener);
    WebSocketService.off("inserted-agama", this.insertOrDeleteAgamaListener);
    WebSocketService.off("updated-agama", this.updateAgamaListener);
    WebSocketService.off("deleted-agama", this.insertOrDeleteAgamaListener);
    WebSocketService.off("graduated-anggota-rombel", this.insertListener);
  },
  methods: {
    async getListAgama() {
      try {
        const response = await this.axios.post('/kesiswaan/agama/list', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listAgama = response.data.agama;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListStatus() {
      try {
        const response = await this.axios.post('/kesiswaan/status-siswa/list', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listStatus = response.data.statusSiswa;
          this.formCreate.status_siswa = this.listStatus.filter((s) => s.status_siswa_id == 1)[0];
          this.editTemplateSiswa();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    editTemplateSiswa() {
      const terbanyak = this.listAgama.length > this.listStatus.length ? this.listAgama.length : this.listStatus.length;
      const la = this.listAgama;
      const ls = this.listStatus;
      for (let i = 0; i < terbanyak; i++) {
        this.templateSiswa.push({
          nis: '',
          nama: '',
          jenis_kelamin: '',
          agama_id: la[i] ? `${la[i].agama_id}: ${la[i].nama}` : '',
          kontak: '',
          status_siswa_id: ls[i] ? `${ls[i].status_siswa_id}: ${ls[i].singkatan} (${ls[i].keterangan})` : '',
          va: ''
        })
      }
    },
    async getDataSiswa() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      try {
        const response = await this.axios.post('/kesiswaan/siswa', {
          key: this.key,
          jenis_kelamin: Object(this.jenisKelamin).kode,
          agama_id: Object(this.agama).agama_id,
          status_siswa_id: Object(this.statusSiswa).status_siswa_id,
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataSiswa = response.data.siswa;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          this.tercentang = [];
          this.semuaTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getSiswaNisKosong() {
      try {
        const response = await this.axios.post('/kesiswaan/siswa/list-nis-kosong', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.siswaNisKosong = response.data.siswaNisKosong;
          let no = 1;
          for (const s of this.siswaNisKosong) {
            s.no = no++;
            s.nis = '';
            s.va = '';
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataSiswa();
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const newData = {
        nis: this.formCreate.nis,
        nama: this.formCreate.nama,
        jenis_kelamin: this.formCreate.jenis_kelamin,
        agama_id: this.formCreate.agama.agama_id,
        kontak: this.formCreate.kontak,
        status_siswa_id: this.formCreate.status_siswa.status_siswa_id,
        va: this.formCreate.va
      }
      try {
        const response = await this.axios.post('/kesiswaan/siswa/tambah', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              nis: null,
              nama: null,
              jenis_kelamin: null,
              agama: null,
              kontak: null,
              status_siswa: this.listStatus.filter((s) => s.status_siswa_id == 1)[0],
              va: null
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      this.selectedData = { ...d };
      this.selectedData.status_siswa = {
        status_siswa_id: this.selectedData.status_siswa_id,
        singkatan: this.selectedData.singkatan,
        keterangan: this.selectedData.keterangan,
      };
      this.selectedData.agama = {
        agama_id: this.selectedData.agama_id,
        nama: this.selectedData.nama_agama,
      }
      this.formEdit = { ...this.selectedData };
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        const newData = {
          siswa_id: this.formEdit.siswa_id,
          nis: this.formEdit.nis,
          nama: this.formEdit.nama,
          jenis_kelamin: this.formEdit.jenis_kelamin,
          agama_id: this.formEdit.agama.agama_id,
          kontak: this.formEdit.kontak,
          status_siswa_id: this.formEdit.status_siswa.status_siswa_id,
          va: this.formEdit.va
        }
        try {
          const response = await this.axios.post('/kesiswaan/siswa/perbarui', newData, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Siswa <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
            <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/kesiswaan/siswa/hapus', {
          siswa_id: d.siswa_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(d) {
      const ditemukan = this.tercentang.findIndex((s) =>
        s == d.siswa_id
      );
      if (ditemukan >= 0) {
        this.tercentang.splice(ditemukan, 1);
      } else {
        this.tercentang.push(d.siswa_id);
      }
      if (this.tercentang.length == this.dataSiswa.length) {
        this.semuaTercentang = true;
      } else {
        this.semuaTercentang = false;
      }
    },
    centangSemua() {
      this.tercentang = []
      if (this.semuaTercentang) {
        for (const d of this.dataSiswa) {
          d.centang = false;
          this.tercentang = [];
        }
      } else {
        for (const d of this.dataSiswa) {
          d.centang = true;
          this.tercentang.push(d.siswa_id);
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data Siswa terpilih?<br/>&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected()
        }
      });
    },
    async deleteSelected() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post("/kesiswaan/siswa/hapus/multiple", {
          dataToDelete: this.tercentang,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    handleInputFile(event) {
      const file = event.target.files[0];
      if (!file || file.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silakan pilih file dengan ekstensi .csv sesuai dengan contoh file!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          this.$refs.fileInput.value = null;
          document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.selectedFile = file;
        document.getElementById('tombol-unggah').setAttribute('data-bs-dismiss', 'modal');
        return;
      }
    },
    async unggahFile() {
      if (this.selectedFile.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silakan pilih file dengan ekstensi .csv sesuai dengan contoh file!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.$refs.fileInput.value = null;
        document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        let memuat = this.$loading.show({
          loader: 'spinner'
        });
        const formData = new FormData();
        formData.append('csvFile', this.selectedFile);
        try {
          const response = await this.axios.post('/kesiswaan/siswa/tambah/multiple', formData, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk menambahkan data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    handleInputFileNis(event) {
      const file = event.target.files[0];
      if (!file || file.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silakan pilih file dengan ekstensi .csv sesuai dengan template!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          this.$refs.fileInputNis.value = null;
          document.getElementById('tombol-unggah-nis').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.selectedFileNis = file;
        document.getElementById('tombol-unggah-nis').setAttribute('data-bs-dismiss', 'modal');
        return;
      }
    },
    async unggahFileNis() {
      if (this.selectedFileNis.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silakan pilih file dengan ekstensi .csv sesuai dengan contoh file!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          document.getElementById('tombol-unggah-nis').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.$refs.fileInput.value = null;
        document.getElementById('tombol-unggah-nis').removeAttribute('data-bs-dismiss');
        let memuat = this.$loading.show({
          loader: 'spinner'
        });
        const formData = new FormData();
        formData.append('csvFile', this.selectedFileNis);
        try {
          const response = await this.axios.post('/kesiswaan/siswa/perbarui-nis/multiple', formData, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk menambahkan data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
  }
}
</script>

<style></style>