<template>
  <h4>Ganti Kata Sandi</h4>
  <div class="card shadow">
    <div class="card-body">
      <div class="col-md-9 col-lg-7 col-xl-6">
        <form @submit.prevent="gantiSandi" class="needs-validation" novalidate>
          <div class="row mb-2">
            <label class="col-sm-4 col-form-label">Sandi Baru</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" placeholder="Kata Sandi Baru" v-model="password" required
                pattern="[A-Za-z0-9~!@#$%^&*\(\)_\-\=:;<>,.?]{8,}" @keyup="verifikasiSandi">
              <div class="invalid-feedback">Kata sandi setidaknya terdiri dari 8 karakter tanpa karakter { } [ ] | \ / ' "
                maupun spasi.</div>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-4 col-form-label">Konfirmasi Sandi Baru</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" placeholder="Masukkan Ulang Kata Sandi Baru"
                v-model="verifyPassword" required :pattern="password" @keyup="verifikasiSandi">
              <div class="invalid-feedback" v-if="password">Sandi tidak sama.</div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary d-flex ms-auto">Perbarui Kata Sandi</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GantiSandiView',
  data() {
    return {
      password: null,
      verifyPassword: null,
      sesuai: false
    }
  },
  created() { },
  methods: {
    verifikasiSandi() {
      const formatPassword = /[A-Za-z0-9~!@#$%^&*()_\-=:;<>,.?]{8,}/g;
      const formatSesuai = new String(this.password).match(formatPassword);
      if (formatSesuai) {
        if (this.password === this.verifyPassword) {
          this.sesuai = true;
        } else {
          this.sesuai = false;
        }
      } else {
        this.sesuai = false;
      }
    },
    async gantiSandi() {
      if (!this.sesuai) {
        const forms = document.querySelectorAll('.needs-validation')
        Array.from(forms).forEach(form => {
          form.classList.add('was-validated')
        })
      } else {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post(this.$auth + '/update/by-user', {
            password: this.password
          }, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              html: response.data.message,
              icon: 'success',
              confirmButtonText: 'Baik',
            }).then(() => {
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              window.location.href = this.$sso + '/' + this.$appId
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Akses tidak diizinkan.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
    }
  }
}
</script>

<style></style>