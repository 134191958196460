<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-md-between justify-content-end mb-2 mx-auto gap-2">
      <div class="col-12 p-0 col-md-auto d-inline-flex">
        <h5 class="my-auto">Mata Pelajaran</h5>
      </div>
      <div class="col-auto p-0">
        <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
          data-bs-target="#tambahData">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
          </svg>Tambahkan Mata Pelajaran
        </button>
      </div>
      <div class="col-auto p-0">
        <select class="form-select form-select-sm shadow-sm" v-model="tahunAjaran" @change="changeLimit">
          <option v-for="ta in dataTahunAjaran" :key="ta" :value="ta">{{ ta.nama }}</option>
        </select>
      </div>
    </div>
    <div v-if="tercentang.length" class="mb-2 text-end">
      <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
    </div>
    <div class="table-responsive shadow vl-parent" ref="table">
      <table class="table table-sm mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>#</th>
            <th>Tingkat</th>
            <th>Jurusan</th>
            <th>Kelompok</th>
            <th class="position-sticky start-0">Kode</th>
            <th>Nama</th>
            <th>KKM</th>
            <th>Jumlah Jam</th>
            <th>
              <div class="d-flex justify-content-between">
                Tindakan
                <input class="form-check-input" type="checkbox" v-model="semuaTercentang" @click="centangSemua">
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataMapel.length">
          <tr v-for="d, index in dataMapel" :key="d">
            <td>{{ index+=1 + pageOffset }}</td>
            <td>{{ d.tingkat }}</td>
            <td>{{ d.kode_jurusan }}</td>
            <td>{{ d.kelompok }}</td>
            <td class="position-sticky start-0">{{ d.kode }}</td>
            <td>{{ d.nama }}</td>
            <td>{{ d.kkm }}</td>
            <td>{{ d.jumlah_jam }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)" data-bs-toggle="modal"
                    data-bs-target="#editData" v-tooltip="'Edit Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                    v-tooltip="'Hapus Data'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
                <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatu(d)">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="9">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span v-if="dataMapel.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataMapel.length }} dari total {{ count }} data
    </span>
  </div>
  <div v-if="dataMapel.length" class="card-footer">
    <div class="row row-cols-auto justify-content-sm-between justify-content-center">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
              @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCount " :key="p">
            <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
          (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
              @click="pageCurrent = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
              @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Mata Pelajaran</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tahun Ajaran</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formCreate.tahunAjaran" required>
                  <option disabled value="">-- Pilih Tahun Ajaran --</option>
                  <option v-for="ta in dataTahunAjaran" :key="ta" :value="ta">{{ ta.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tingkat</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formCreate.tingkat" required>
                  <option disabled value="">-- Pilih Tingkat --</option>
                  <option v-for="t in arrayTingkat" :key="t" :value="t">{{ t }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jurusan</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formCreate.jurusan" required>
                  <option disabled value="">-- Pilih Jurusan --</option>
                  <option v-for="j in dataJurusan" :key="j" :value="j">{{ j.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kelompok</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kelompok/Muatan"
                  v-model="formCreate.kelompok" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode Mata Pelajaran"
                  v-model="formCreate.kode" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Mata Pelajaran"
                  v-model="formCreate.nama" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">KKM</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Nilai KKM"
                  v-model="formCreate.kkm" required>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jumlah Jam</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Jumlah Jam"
                  v-model="formCreate.jumlah_jam" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Mata Pelajaran</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tahun Ajaran</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formEdit.tahunAjaran" required>
                  <option disabled value="">-- Pilih Tahun Ajaran --</option>
                  <option v-for="ta in dataTahunAjaran" :key="ta" :value="ta">{{ ta.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tingkat</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formEdit.tingkat" required>
                  <option disabled value="">-- Pilih Tingkat --</option>
                  <option v-for="t in arrayTingkat" :key="t" :value="t">{{ t }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jurusan</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formEdit.jurusan" required>
                  <option disabled value="">-- Pilih Jurusan --</option>
                  <option v-for="j in dataJurusan" :key="j" :value="j">{{ j.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kelompok</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kelompok/Muatan"
                  v-model="formEdit.kelompok" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode Mata Pelajaran"
                  v-model="formEdit.kode" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama Mata Pelajaran"
                  v-model="formEdit.nama" required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">KKM</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Nilai KKM" v-model="formEdit.kkm"
                  required>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jumlah Jam</label>
              <div class="col-sm-9">
                <input type="number" class="form-control form-control-sm" placeholder="Jumlah Jam"
                  v-model="formEdit.jumlah_jam" required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'MataPelajaran',
  data() {
    return {
      insertListener: (d) => {
        const data = JSON.parse(d);
        if (data.tahun_ajaran_id == this.tahunAjaran.tahun_ajaran_id) {
          this.getDataMapel();
        }
      },
      updateListener: (d) => {
        const data = JSON.parse(d);
        if (data.tahun_ajaran_id == this.tahunAjaran.tahun_ajaran_id) {
          if (Object(this.formEdit).mapel_id == data.mapel_id) {
            document.getElementById('tutupFormEdit').click();
          }
          this.getDataMapel();
        }
      },
      deleteListener: (d) => {
        const data = JSON.parse(d);
        if (data.tahun_ajaran_id == this.tahunAjaran.tahun_ajaran_id) {
          if (Object(this.formEdit).mapel_id == data.mapel_id) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.pageCurrent == this.pageCount && this.dataMapel.length == 1) {
            if (this.pageCurrent == 1) {
              this.getDataMapel();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getDataMapel();
          }
        }
      },
      multipleDeleteListener: (d) => {
        const data = JSON.parse(d);
        if (data.listTahunAjaranId.some((t) => {
          return t.tahun_ajaran_id = this.tahunAjaran.tahun_ajaran_id
        })) {
          if (data.listMapelId.some((m) => {
            return m == Object(this.formEdit).mapel_id
          })) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.pageCurrent == this.pageCount && this.dataMapel.length <= data.listMapelId.length) {
            if (this.pageCurrent == 1) {
              this.getDataMapel();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getDataMapel();
          }
        }
      },
      taListener: () => {
        this.getListDataTahunAjaran();
      },
      jurusanListener: (d) => {
        this.getListDataJurusan();
        for (const r of this.dataRombel) {
          if (r.jurusan_id == d) {
            this.getDataMapel();
          }
        }
      },
      dataMapel: [],
      arrayTingkat: ['-', 'X', 'XI', 'XII'],
      dataTahunAjaran: [],
      tahunAjaran: null,
      dataJurusan: [],
      arrayLimit: [10, 25, 50, 100],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      formCreate: {
        tahunAjaran: '',
        tingkat: '',
        jurusan: '',
        kelompok: null,
        kode: null,
        nama: null,
        kkm: null,
        jumlah_jam: null,
      },
      selectedData: {},
      formEdit: {},
      tercentang: [],
      semuaTercentang: false,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataMapel();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataMapel();
      } else {
        this.pageCurrent = 1;
      }
    },
    tahunAjaran() {
      if (this.pageCurrent == 1) {
        this.getDataMapel();
      } else {
        this.pageCurrent = 1;
      }
    }
  },
  mounted() {
    this.getListDataTahunAjaran();
    this.getListDataJurusan();
    WebSocketService.on("inserted-mapel", this.insertListener);
    WebSocketService.on("updated-mapel", this.updateListener);
    WebSocketService.on("deleted-mapel", this.deleteListener);
    WebSocketService.on("deleted-multiple-mapel", this.multipleDeleteListener);
    WebSocketService.on("inserted-tahun-ajaran", this.taListener);
    WebSocketService.on("updated-tahun-ajaran", this.taListener);
    WebSocketService.on("deleted-tahun-ajaran", this.taListener);
    WebSocketService.on("deleted-multiple-tahun-ajaran", this.taListener);
    WebSocketService.on("inserted-jurusan", this.jurusanListener);
    WebSocketService.on("updated-jurusan", this.jurusanListener);
    WebSocketService.on("deleted-jurusan", this.jurusanListener);
    WebSocketService.on("deleted-multiple-jurusan", this.jurusanListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-mapel", this.insertListener);
    WebSocketService.off("updated-mapel", this.updateListener);
    WebSocketService.off("deleted-mapel", this.deleteListener);
    WebSocketService.off("deleted-multiple-mapel", this.multipleDeleteListener);
    WebSocketService.off("inserted-tahun-ajaran", this.taListener);
    WebSocketService.off("updated-tahun-ajaran", this.taListener);
    WebSocketService.off("deleted-tahun-ajaran", this.taListener);
    WebSocketService.off("deleted-multiple-tahun-ajaran", this.taListener);
    WebSocketService.off("inserted-jurusan", this.jurusanListener);
    WebSocketService.off("updated-jurusan", this.jurusanListener);
    WebSocketService.off("deleted-jurusan", this.jurusanListener);
    WebSocketService.off("deleted-multiple-jurusan", this.jurusanListener);
  },
  methods: {
    async getListDataTahunAjaran() {
      try {
        const response = await this.axios.post('/kurikulum/tahun-ajaran/list', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataTahunAjaran = response.data.tahunAjaran;
          this.tahunAjaran = this.dataTahunAjaran.filter((d) => d.periode_aktif == 1)[0];
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListDataJurusan() {
      try {
        const response = await this.axios.post('/kurikulum/jurusan/list', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataJurusan = response.data.dataJurusan;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataMapel() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      try {
        const response = await this.axios.post('/kurikulum/mapel', {
          tahun_ajaran_id: this.tahunAjaran.tahun_ajaran_id,
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataMapel = response.data.dataMapel;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          this.tercentang = [];
          this.semuaTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const newData = {
        tahun_ajaran_id: this.formCreate.tahunAjaran.tahun_ajaran_id,
        tingkat: this.formCreate.tingkat,
        jurusan_id: this.formCreate.jurusan.jurusan_id,
        kelompok: this.formCreate.kelompok,
        kode: this.formCreate.kode,
        nama: this.formCreate.nama,
        kkm: this.formCreate.kkm,
        jumlah_jam: this.formCreate.jumlah_jam
      }
      try {
        const response = await this.axios.post('/kurikulum/mapel/tambah', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              tahunAjaran: '',
              tingkat: '',
              jurusan: '',
              kelompok: null,
              kode: null,
              nama: null,
              kkm: null,
              jumlah_jam: null,
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      this.selectedData = { ...d };
      this.selectedData.tahunAjaran = this.dataTahunAjaran.filter((ta) => ta.tahun_ajaran_id == d.tahun_ajaran_id)[0];
      this.selectedData.jurusan = this.dataJurusan.filter((j) => j.jurusan_id == d.jurusan_id)[0];
      this.formEdit = { ...this.selectedData };
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        const newData = {
          mapel_id: this.formEdit.mapel_id,
          tahun_ajaran_id: this.formEdit.tahunAjaran.tahun_ajaran_id,
          tingkat: this.formEdit.tingkat,
          jurusan_id: this.formEdit.jurusan.jurusan_id,
          kelompok: this.formEdit.kelompok,
          kode: this.formEdit.kode,
          nama: this.formEdit.nama,
          kkm: this.formEdit.kkm,
          jumlah_jam: this.formEdit.jumlah_jam
        }
        try {
          const response = await this.axios.post('/kurikulum/mapel/update', newData, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: 'Berhasil memperbarui data Mata Pelajaran',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Mata Pelajaran <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
            <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/kurikulum/mapel/hapus', {
          mapel_id: d.mapel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(d) {
      const ditemukan = this.tercentang.findIndex((j) =>
        j == d.mapel_id
      );
      if (ditemukan >= 0) {
        this.tercentang.splice(ditemukan, 1);
      } else {
        this.tercentang.push(d.mapel_id);
      }
      if (this.tercentang.length == this.dataMapel.length) {
        this.semuaTercentang = true;
      } else {
        this.semuaTercentang = false;
      }
    },
    centangSemua() {
      this.tercentang = []
      if (this.semuaTercentang) {
        for (const d of this.dataMapel) {
          d.centang = false;
          this.tercentang = [];
        }
      } else {
        for (const d of this.dataMapel) {
          d.centang = true;
          this.tercentang.push(d.mapel_id);
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data Mata Pelajaran terpilih?<br/>
          &#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected()
        }
      });
    },
    async deleteSelected() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post("/kurikulum/mapel/hapus/multiple", {
          dataToDelete: this.tercentang,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>