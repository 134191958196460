<template>
  <h4>Administrasi Kurikulum</h4>
  <div class="card shadow vl-parent" ref="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item">
          <router-link to="/kurikulum/tahun-ajaran" class="nav-link" active-class="active">Tahun Ajaran</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/semester" class="nav-link" active-class="active">Semester</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/jurusan" class="nav-link" active-class="active">Jurusan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/rombel" class="nav-link" active-class="active">Rombel</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/anggota-rombel" class="nav-link" active-class="active">Anggota
            Rombel</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/mapel" class="nav-link" active-class="active">Mapel</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kurikulum/aspek-tugas" class="nav-link" active-class="active">Aspek Tugas</router-link>
        </li>
      </ul>
    </div>
    <div v-if="!$route.params.data">
      <div class="card-body">
        <h5>Data Bagian Kurikulum</h5>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Tahun Ajaran</span>
                <router-link to="/kurikulum/tahun-ajaran" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.tahunAjaran">
                    {{ dataKurikulum.tahunAjaran || 0 }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Semester</span>
                <router-link to="/kurikulum/semester" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.semester">
                    {{ dataKurikulum.semester || 0 }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Jurusan</span>
                <router-link to="/kurikulum/jurusan" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.jurusan">
                    {{ dataKurikulum.jurusan || 0 }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Rombongan Belajar</span>
                <router-link to="/kurikulum/rombel" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.rombel">
                    {{ dataKurikulum.rombel || 0 }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Anggota Rombel</span>
                <router-link to="/kurikulum/anggota-rombel" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.anggotaRombel">
                    {{ dataKurikulum.anggotaRombel || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Mata Pelajaran</span>
                <router-link to="/kurikulum/mapel" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.mapel">
                    {{ dataKurikulum.mapel }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Aspek Tugas</span>
                <router-link to="/kurikulum/aspek-tugas" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKurikulum.aspekTugas">
                    {{ dataKurikulum.aspekTugas }}
                  </span>
                </transition><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tahun-ajaran v-else-if="$route.params.data === 'tahun-ajaran'" />
    <data-semester v-else-if="$route.params.data === 'semester'" />
    <data-jurusan v-else-if="$route.params.data === 'jurusan'" />
    <rombongan-belajar v-else-if="$route.params.data === 'rombel'" />
    <anggota-rombel v-else-if="$route.params.data === 'anggota-rombel'" />
    <mata-pelajaran v-else-if="$route.params.data === 'mapel'" />
    <aspek-tugas v-else-if="$route.params.data === 'aspek-tugas'" />
    <not-found v-else />
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import NotFound from '@/components/NotFound.vue';
import TahunAjaran from '@/components/TahunAjaran.vue';
import DataSemester from '@/components/DataSemester.vue';
import DataJurusan from '@/components/DataJurusan.vue';
import RombonganBelajar from '@/components/RombonganBelajar.vue';
import AnggotaRombel from '@/components/AnggotaRombel.vue';
import MataPelajaran from '@/components/MataPelajaran.vue';
import AspekTugas from '@/components/AspekTugas.vue';
export default {
  name: 'KurikulumView',
  components: {
    NotFound, TahunAjaran, DataSemester, DataJurusan, RombonganBelajar,
    AnggotaRombel,
    MataPelajaran, AspekTugas
  },
  data() {
    return {
      dataListener: () => {
        this.getDataKurikulum();
      },
      dataKurikulum: {
        tahunAjaran: 0,
        semester: 0,
        jurusan: 0,
        rombel: 0,
        anggotaRombel: 0,
        mapel: 0,
        aspekTugas: 0,
      }
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      container: this.$refs.card,
      loader: 'spinner'
    });
    this.getDataKurikulum();
    setTimeout(() => {
      memuat.hide();
    }, 250);
    WebSocketService.on("inserted-tahun-ajaran", this.dataListener);
    WebSocketService.on("updated-tahun-ajaran", this.dataListener);
    WebSocketService.on("deleted-tahun-ajaran", this.dataListener);
    WebSocketService.on("deleted-multiple-tahun-ajaran", this.dataListener);
    WebSocketService.on("inserted-semester", this.dataListener);
    WebSocketService.on("updated-semester", this.dataListener);
    WebSocketService.on("deleted-semester", this.dataListener);
    WebSocketService.on("deleted-multiple-semester", this.dataListener);
    WebSocketService.on("inserted-jurusan", this.dataListener);
    WebSocketService.on("updated-jurusan", this.dataListener);
    WebSocketService.on("deleted-jurusan", this.dataListener);
    WebSocketService.on("deleted-multiple-jurusan", this.dataListener);
    WebSocketService.on("inserted-rombel", this.dataListener);
    WebSocketService.on("updated-rombel", this.dataListener);
    WebSocketService.on("deleted-rombel", this.dataListener);
    WebSocketService.on("deleted-multiple-rombel", this.dataListener);
    WebSocketService.on("assigned-anggota-rombel", this.dataListener);
    WebSocketService.on("moved-anggota-rombel", this.dataListener);
    WebSocketService.on("deleted-anggota-rombel", this.dataListener);
    WebSocketService.on("deleted-multiple-anggota-rombel", this.dataListener);
    WebSocketService.on("inserted-mapel", this.dataListener);
    WebSocketService.on("updated-mapel", this.dataListener);
    WebSocketService.on("deleted-mapel", this.dataListener);
    WebSocketService.on("deleted-multiple-mapel", this.dataListener);
    WebSocketService.on("inserted-aspek-tugas", this.dataListener);
    WebSocketService.on("updated-aspek-tugas", this.dataListener);
    WebSocketService.on("deleted-aspek-tugas", this.dataListener);
    WebSocketService.on("deleted-multiple-aspek-tugas", this.dataListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-tahun-ajaran", this.dataListener);
    WebSocketService.off("updated-tahun-ajaran", this.dataListener);
    WebSocketService.off("deleted-tahun-ajaran", this.dataListener);
    WebSocketService.off("deleted-multiple-tahun-ajaran", this.dataListener);
    WebSocketService.off("inserted-semester", this.dataListener);
    WebSocketService.off("updated-semester", this.dataListener);
    WebSocketService.off("deleted-semester", this.dataListener);
    WebSocketService.off("deleted-multiple-semester", this.dataListener);
    WebSocketService.off("inserted-jurusan", this.dataListener);
    WebSocketService.off("updated-jurusan", this.dataListener);
    WebSocketService.off("deleted-jurusan", this.dataListener);
    WebSocketService.off("deleted-multiple-jurusan", this.dataListener);
    WebSocketService.off("inserted-rombel", this.dataListener);
    WebSocketService.off("updated-rombel", this.dataListener);
    WebSocketService.off("deleted-rombel", this.dataListener);
    WebSocketService.off("deleted-multiple-rombel", this.dataListener);
    WebSocketService.off("assigned-anggota-rombel", this.dataListener);
    WebSocketService.off("moved-anggota-rombel", this.dataListener);
    WebSocketService.off("deleted-anggota-rombel", this.dataListener);
    WebSocketService.off("deleted-multiple-anggota-rombel", this.dataListener);
    WebSocketService.off("inserted-mapel", this.dataListener);
    WebSocketService.off("updated-mapel", this.dataListener);
    WebSocketService.off("deleted-mapel", this.dataListener);
    WebSocketService.off("deleted-multiple-mapel", this.dataListener);
    WebSocketService.off("inserted-aspek-tugas", this.dataListener);
    WebSocketService.off("updated-aspek-tugas", this.dataListener);
    WebSocketService.off("deleted-aspek-tugas", this.dataListener);
    WebSocketService.off("deleted-multiple-aspek-tugas", this.dataListener);
  },
  methods: {
    async getDataKurikulum() {
      try {
        const response = await this.axios.post('/kurikulum/data/all', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          const data = response.data;
          this.dataKurikulum.tahunAjaran = this.dataKurikulum.tahunAjaran == data.tahunAjaran ? this.dataKurikulum.tahunAjaran : data.tahunAjaran;
          this.dataKurikulum.semester = this.dataKurikulum.semester == data.semester ? this.dataKurikulum.semester : data.semester;
          this.dataKurikulum.jurusan = this.dataKurikulum.jurusan == data.jurusan ? this.dataKurikulum.jurusan : data.jurusan;
          this.dataKurikulum.rombel = this.dataKurikulum.rombel == data.rombel ? this.dataKurikulum.rombel : data.rombel;
          this.dataKurikulum.anggotaRombel = this.dataKurikulum.anggotaRombel == data.anggotaRombel ? this.dataKurikulum.anggotaRombel : data.anggotaRombel;
          this.dataKurikulum.mapel = this.dataKurikulum.mapel == data.mapel ? this.dataKurikulum.mapel : data.mapel;
          this.dataKurikulum.aspekTugas = this.dataKurikulum.aspekTugas == data.aspekTugas ? this.dataKurikulum.aspekTugas : data.aspekTugas;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>