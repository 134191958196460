<template>
    <div class="card-body">
        <div class="row row-cols-auto justify-content-md-between justify-content-end mb-2 mx-auto gap-2">
            <div class="col-12 p-0 col-md-auto d-inline-flex">
                <h5 class="my-auto">Rombongan Belajar</h5>
            </div>
            <div class="col-auto p-0">
                <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
                    data-bs-target="#tambahData">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                    </svg>Tambahkan Rombongan Belajar
                </button>
            </div>
            <div class="col-auto p-0">
                <select class="form-select form-select-sm shadow-sm" v-model="semester" @change="changeLimit">
                    <option v-for="s in dataSemester" :key="s" :value="s">{{ s.nama }}</option>
                </select>
            </div>
        </div>
        <div v-if="tercentang.length" class="mb-2 text-end">
            <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
        </div>
        <div class="table-responsive shadow vl-parent" ref="table">
            <table class="table table-sm mb-0">
                <thead class="table-secondary align-middle">
                    <tr>
                        <th>#</th>
                        <th>Tingkat</th>
                        <th>Jurusan</th>
                        <th class="position-sticky start-0">Nama</th>
                        <th>Wali Kelas</th>
                        <th>Guru BK</th>
                        <th>
                            <div class="d-flex justify-content-between">
                                Tindakan
                                <input class="form-check-input" type="checkbox" v-model="semuaTercentang"
                                    @click="centangSemua">
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="dataRombel.length">
                    <tr v-for="d, index in dataRombel" :key="d">
                        <td>{{ index+=1 + pageOffset }}</td>
                        <td>{{ d.tingkat }}</td>
                        <td>{{ d.kode }}</td>
                        <td class="position-sticky start-0">{{ d.nama }}</td>
                        <td>{{ d.nama_wali_kelas }}</td>
                        <td>{{ d.nama_guru_bk }}</td>
                        <td>
                            <div class="d-flex justify-content-between">
                                <div class="hstack gap-1 me-1">
                                    <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)"
                                        data-bs-toggle="modal" data-bs-target="#editData" v-tooltip="'Edit Data'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                                            class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                    </button>
                                    <div class="vr"></div>
                                    <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                                        v-tooltip="'Hapus Data'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                                            class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </div>
                                <input class="form-check-input" type="checkbox" v-model="d.centang"
                                    @click="centangSatu(d)">
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-center fst-italic py-3" colspan="7">Data tidak ditemukan.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-if="dataRombel.length" class="small text-muted fst-italic">
            Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataRombel.length }} dari total {{ count }} data
        </span>
    </div>
    <div v-if="dataRombel.length" class="card-footer">
        <div class="row row-cols-auto justify-content-sm-between justify-content-center">
            <div class="row row-cols-auto">
                <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
                <div class="col-auto ps-1">
                    <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
                        <option v-for="l in arrayLimit" :key="l" :value="l">
                            {{ l }} baris
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-auto">
                <ul class="pagination pagination-sm shadow mb-0">
                    <li class="page-item">
                        <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                            @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
                    </li>
                    <li class="page-item" v-for="p in  pageCount " :key="p">
                        <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
                        <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                            @click="pageCurrent = p">{{ p }}</button>
                        <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                            @click="pageCurrent = p">{{ p }}</button>
                        <button
                            v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                            class="page-link" @click="pageCurrent = p">{{ p }}</button>
                        <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                    (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                    (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                            @click="pageCurrent = p">...</button>
                    </li>
                    <li class="page-item">
                        <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                            @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content vl-parent" ref="formCreate">
                <form @submit.prevent="simpanData">
                    <div class="modal-header">
                        <legend class="modal-title">Tambah Data Rombongan Belajar</legend>
                        <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Semester</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formCreate.semester" required>
                                    <option disabled value="">-- Pilih Semester --</option>
                                    <option v-for="s in dataSemester" :key="s" :value="s">{{ s.nama }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Tingkat</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formCreate.tingkat" required>
                                    <option disabled value="">-- Pilih Tingkat --</option>
                                    <option v-for="t in arrayTingkat" :key="t" :value="t">{{ t }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Jurusan</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formCreate.jurusan" required>
                                    <option disabled value="">-- Pilih Jurusan --</option>
                                    <option v-for="j in dataJurusan" :key="j" :value="j">{{ j.nama }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" placeholder="Nama Rombel"
                                    v-model="formCreate.nama" required>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Wali Kelas</label>
                            <div class="col-sm-9">
                                <v-select :options="dataGuru" label="nama" :placeholder="'Pilih Wali Kelas'"
                                    :selectOnTab="true" :clearable="false" v-model="formCreate.wali_kelas">
                                    <template #no-options>
                                        Data tidak ditemukan.
                                    </template>
                                    <template #search="{ attributes, events }">
                                        <input class="vs__search" :required="!formCreate.wali_kelas" v-bind="attributes"
                                            v-on="events">
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Guru BK</label>
                            <div class="col-sm-9">
                                <v-select :options="dataGuru" label="nama" :placeholder="'Pilih Guru BK'"
                                    :selectOnTab="true" :clearable="false" v-model="formCreate.guru_bk">
                                    <template #no-options>
                                        Data tidak ditemukan.
                                    </template>
                                    <template #search="{ attributes, events }">
                                        <input class="vs__search" :required="!formCreate.guru_bk" v-bind="attributes"
                                            v-on="events">
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Simpan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content vl-parent" ref="formEdit">
                <form @submit.prevent="updateData">
                    <div class="modal-header">
                        <legend class="modal-title">Edit Data Rombongan Belajar</legend>
                        <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Semester</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formEdit.semester" required>
                                    <option disabled value="">-- Pilih Semester --</option>
                                    <option v-for="s in dataSemester" :key="s" :value="s">{{ s.nama }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Tingkat</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formEdit.tingkat" required>
                                    <option disabled value="">-- Pilih Tingkat --</option>
                                    <option v-for="t in arrayTingkat" :key="t" :value="t">{{ t }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Jurusan</label>
                            <div class="col-sm-9">
                                <select class="form-select form-select-sm" v-model="formEdit.jurusan" required>
                                    <option disabled value="">-- Pilih Jurusan --</option>
                                    <option v-for="j in dataJurusan" :key="j" :value="j">{{ j.nama }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" placeholder="Nama Rombel"
                                    v-model="formEdit.nama" required>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Wali Kelas</label>
                            <div class="col-sm-9">
                                <v-select :options="dataGuru" label="nama" :placeholder="'Pilih Wali Kelas'"
                                    :selectOnTab="true" :clearable="false" v-model="formEdit.wali_kelas">
                                    <template #no-options>
                                        Data tidak ditemukan.
                                    </template>
                                    <template #search="{ attributes, events }">
                                        <input class="vs__search" :required="!formEdit.wali_kelas" v-bind="attributes"
                                            v-on="events">
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-3 col-form-label col-form-label-sm">Guru BK</label>
                            <div class="col-sm-9">
                                <v-select :options="dataGuru" label="nama" :placeholder="'Pilih Guru BK'"
                                    :selectOnTab="true" :clearable="false" v-model="formEdit.guru_bk">
                                    <template #no-options>
                                        Data tidak ditemukan.
                                    </template>
                                    <template #search="{ attributes, events }">
                                        <input class="vs__search" :required="!formEdit.guru_bk" v-bind="attributes"
                                            v-on="events">
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Perbarui</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
    name: 'RombonganBelajar',
    data() {
        return {
            insertListener: (d) => {
                const data = JSON.parse(d);
                if (data.semester_id == this.semester.semester_id) {
                    this.getDataRombel();
                }
            },
            updateListener: (d) => {
                const data = JSON.parse(d);
                if (data.semester_id == this.semester.semester_id) {
                    if (Object(this.formEdit).rombel_id == data.rombel_id) {
                        document.getElementById('tutupFormEdit').click();
                    }
                    this.getDataRombel();
                }
            },
            deleteListener: (d) => {
                const data = JSON.parse(d);
                if (data.semester_id == this.semester.semester_id) {
                    if (Object(this.formEdit).rombel_id == data.rombel_id) {
                        document.getElementById('tutupFormEdit').click();
                    }
                    if (this.pageCurrent == this.pageCount && this.dataRombel.length == 1) {
                        if (this.pageCurrent == 1) {
                            this.getDataRombel();
                        } else {
                            this.pageCurrent = 1;
                        }
                    } else {
                        this.getDataRombel();
                    }
                }
            },
            multipleDeleteListener: (d) => {
                const data = JSON.parse(d);
                if (data.listSemesterId.some((s) => {
                    return s.semester_id = this.semester.semester_id
                })) {
                    if (data.listRombelId.some((r) => {
                        return r == Object(this.formEdit).rombel_id
                    })) {
                        document.getElementById('tutupFormEdit').click();
                    }
                    if (this.pageCurrent == this.pageCount && this.dataRombel.length <= data.listRombelId.length) {
                        if (this.pageCurrent == 1) {
                            this.getDataRombel();
                        } else {
                            this.pageCurrent = 1;
                        }
                    } else {
                        this.getDataRombel();
                    }
                }
            },
            semesterListener: () => {
                this.getListDataSemester();
            },
            jurusanListener: (d) => {
                this.getListDataJurusan();
                for (const r of this.dataRombel) {
                    if (r.jurusan_id == d) {
                        this.getDataRombel();
                    }
                }
            },
            guruListener: (d) => {
                const data = JSON.parse(d);
                if (data.role == "guru") {
                    this.getListDataGuru();
                    this.getDataRombel();
                }
            },
            dataRombel: [],
            arrayTingkat: ['X', 'XI', 'XII'],
            dataSemester: [],
            semester: null,
            dataJurusan: [],
            dataGuru: [],
            arrayLimit: [10, 25, 50, 100],
            count: 0,
            pageCurrent: 1,
            pageOffset: 0,
            pageLimit: 10,
            pageCount: 0,
            formCreate: {
                semester: '',
                tingkat: '',
                jurusan: '',
                nama: null,
                wali_kelas: null,
                guru_bk: null,
            },
            selectedData: {},
            formEdit: {},
            tercentang: [],
            semuaTercentang: false,
        }
    },
    computed: {
        token() {
            return this.$store.getters.getToken
        },
        user() {
            return this.$store.getters.getUserData;
        },
    },
    watch: {
        pageCurrent() {
            this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
            this.getDataRombel();
        },
        pageLimit() {
            if (this.pageCurrent == 1) {
                this.getDataRombel();
            } else {
                this.pageCurrent = 1;
            }
        },
        semester() {
            if (this.pageCurrent == 1) {
                this.getDataRombel();
            } else {
                this.pageCurrent = 1;
            }
        }
    },
    mounted() {
        this.getListDataSemester();
        this.getListDataJurusan();
        this.getListDataGuru();
        WebSocketService.on("inserted-rombel", this.insertListener);
        WebSocketService.on("updated-rombel", this.updateListener);
        WebSocketService.on("deleted-rombel", this.deleteListener);
        WebSocketService.on("deleted-multiple-rombel", this.multipleDeleteListener);
        WebSocketService.on("inserted-semester", this.semesterListener);
        WebSocketService.on("updated-semester", this.semesterListener);
        WebSocketService.on("deleted-semester", this.semesterListener);
        WebSocketService.on("deleted-multiple-semester", this.semesterListener);
        WebSocketService.on("inserted-jurusan", this.jurusanListener);
        WebSocketService.on("updated-jurusan", this.jurusanListener);
        WebSocketService.on("deleted-jurusan", this.jurusanListener);
        WebSocketService.on("deleted-multiple-jurusan", this.jurusanListener);
        WebSocketService.on("inserted-user", this.guruListener);
        WebSocketService.on("updated-user", this.guruListener);
        WebSocketService.on("deleted-user", this.guruListener);
    },
    beforeUnmount() {
        WebSocketService.off("inserted-rombel", this.insertListener);
        WebSocketService.off("updated-rombel", this.updateListener);
        WebSocketService.off("deleted-rombel", this.deleteListener);
        WebSocketService.off("deleted-multiple-rombel", this.multipleDeleteListener);
        WebSocketService.off("inserted-semester", this.semesterListener);
        WebSocketService.off("updated-semester", this.semesterListener);
        WebSocketService.off("deleted-semester", this.semesterListener);
        WebSocketService.off("deleted-multiple-semester", this.semesterListener);
        WebSocketService.off("inserted-jurusan", this.jurusanListener);
        WebSocketService.off("updated-jurusan", this.jurusanListener);
        WebSocketService.off("deleted-jurusan", this.jurusanListener);
        WebSocketService.off("deleted-multiple-jurusan", this.jurusanListener);
        WebSocketService.off("inserted-user", this.guruListener);
        WebSocketService.off("updated-user", this.guruListener);
        WebSocketService.off("deleted-user", this.guruListener);
    },
    methods: {
        async getListDataSemester() {
            try {
                const response = await this.axios.post('/kurikulum/semester/list', {}, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.dataSemester = response.data.dataSemester;
                    this.semester = this.dataSemester.filter((d) => d.periode_aktif == 1)[0];
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getListDataJurusan() {
            try {
                const response = await this.axios.post('/kurikulum/jurusan/list', {}, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.dataJurusan = response.data.dataJurusan;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getListDataGuru() {
            try {
                const response = await this.axios.post('/user/list/guru', {}, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.dataGuru = response.data.guru;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getDataRombel() {
            let memuat = this.$loading.show({
                container: this.$refs.table,
                loader: 'dots'
            });
            try {
                const response = await this.axios.post('/kurikulum/rombel', {
                    semester_id: this.semester.semester_id,
                    limit: this.pageLimit,
                    offset: this.pageOffset
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.dataRombel = response.data.dataRombel;
                    this.count = response.data.count;
                    this.pageCount = Math.ceil(this.count / this.pageLimit);
                    this.tercentang = [];
                    this.semuaTercentang = false;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        async simpanData() {
            let memuat = this.$loading.show({
                container: this.$refs.formCreate,
                loader: 'spinner'
            });
            const newData = {
                semester_id: this.formCreate.semester.semester_id,
                tingkat: this.formCreate.tingkat,
                jurusan_id: this.formCreate.jurusan.jurusan_id,
                nama: this.formCreate.nama,
                wali_kelas: this.formCreate.wali_kelas.guru_id,
                guru_bk: this.formCreate.guru_bk.guru_id
            }
            try {
                const response = await this.axios.post('/kurikulum/rombel/tambah', newData, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    document.getElementById('tutupFormCreate').click();
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => {
                        this.formCreate = {
                            semester: '',
                            tingkat: '',
                            jurusan: '',
                            nama: null,
                            wali_kelas: null,
                            guru_bk: null,
                        }
                    });
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else if (err.response.status == 403) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Anda tidak memiliki akses untuk menambahkan data.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    } else {
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        editData(d) {
            this.selectedData = { ...d };
            this.selectedData.semester = this.dataSemester.filter((s) => s.semester_id == d.semester_id)[0];
            this.selectedData.jurusan = this.dataJurusan.filter((j) => j.jurusan_id == d.jurusan_id)[0];
            this.selectedData.wali_kelas = this.dataGuru.filter((gwk) => gwk.guru_id == d.wali_kelas)[0];
            this.selectedData.guru_bk = this.dataGuru.filter((gbk) => gbk.guru_id == d.guru_bk)[0];
            this.formEdit = { ...this.selectedData };
        },
        async updateData() {
            let memuat = this.$loading.show({
                container: this.$refs.formEdit,
                loader: 'spinner'
            });
            if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
                this.$swal({
                    title: 'Perhatian',
                    text: 'Anda belum melakukan perubahan',
                    icon: 'warning',
                    confirmButtonText: 'Baik'
                });
            } else {
                const newData = {
                    rombel_id: this.formEdit.rombel_id,
                    semester_id: this.formEdit.semester.semester_id,
                    tingkat: this.formEdit.tingkat,
                    jurusan_id: this.formEdit.jurusan.jurusan_id,
                    nama: this.formEdit.nama,
                    wali_kelas: this.formEdit.wali_kelas.guru_id,
                    guru_bk: this.formEdit.guru_bk.guru_id
                }
                try {
                    const response = await this.axios.post('/kurikulum/rombel/update', newData, {
                        headers: { Authorization: this.token }
                    });
                    if (response.data) {
                        document.getElementById('tutupFormEdit').click();
                        this.$swal({
                            title: 'Sukses',
                            text: 'Berhasil memperbarui data Rombongan Belajar',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                }
                catch (err) {
                    if (err.response) {
                        if (err.response.status == 401) {
                            this.$swal({
                                title: 'Gagal',
                                text: 'Sesi berakhir. Silakan login ulang.',
                                icon: 'error',
                                confirmButtonText: 'Baik'
                            }).then(() => {
                                this.$store.dispatch('logoutUser');
                                window.location.href = this.$sso + "/" + this.$appId;
                            });
                        } else if (err.response.status == 403) {
                            this.$swal({
                                title: 'Gagal',
                                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                                icon: 'error',
                                confirmButtonText: 'Baik'
                            });
                        } else {
                            this.$swal({
                                title: 'Gagal',
                                html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                                icon: 'error',
                                confirmButtonText: 'Baik'
                            });
                        }
                    } else {
                        console.log(err)
                    }
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        confirmDelete(d) {
            this.$swal({
                title: 'Konfirmasi',
                html: `<span class="h5">Anda yakin ingin menghapus data Rombongan Belajar <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
            <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya, Hapus',
                cancelButtonText: 'Batal',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hapusData(d);
                }
            });
        },
        async hapusData(d) {
            let memuat = this.$loading.show({
                opacity: 0,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post('/kurikulum/rombel/hapus', {
                    rombel_id: d.rombel_id
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else if (err.response.status == 403) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Anda tidak memiliki akses untuk menghapus data.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    } else {
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        centangSatu(d) {
            const ditemukan = this.tercentang.findIndex((j) =>
                j == d.rombel_id
            );
            if (ditemukan >= 0) {
                this.tercentang.splice(ditemukan, 1);
            } else {
                this.tercentang.push(d.rombel_id);
            }
            if (this.tercentang.length == this.dataRombel.length) {
                this.semuaTercentang = true;
            } else {
                this.semuaTercentang = false;
            }
        },
        centangSemua() {
            this.tercentang = []
            if (this.semuaTercentang) {
                for (const d of this.dataRombel) {
                    d.centang = false;
                    this.tercentang = [];
                }
            } else {
                for (const d of this.dataRombel) {
                    d.centang = true;
                    this.tercentang.push(d.rombel_id);
                }
            }
        },
        confirmDeleteSelected() {
            this.$swal({
                title: "Konfirmasi",
                html: `Anda yakin ingin menghapus data Rombongan Belajar terpilih?<br/>
                          &#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Ya, Hapus",
                cancelButtonText: "Batal",
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteSelected()
                }
            });
        },
        async deleteSelected() {
            let memuat = this.$loading.show({
                opacity: 0,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post("/kurikulum/rombel/hapus/multiple", {
                    dataToDelete: this.tercentang,
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else if (err.response.status == 403) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Anda tidak memiliki akses untuk menghapus data.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    } else {
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
    }
}
</script>

<style scoped></style>