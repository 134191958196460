<template>
  <div class="card-body">
    <h5>Impor Data PPDB</h5>
    <div class="row row-cols-2 align-items-center">
      <label class="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-form-label hstack gap-1 justify-content-between pe-0">
        <span>Pilih Tahun Ajaran PPDB</span><span>:</span>
      </label>
      <div class="col-auto">
        <select class="form-select form-select-sm shadow" v-model="ta">
          <option v-for="t in listTa" :key="t" :value="t">{{ t.nama }}</option>
        </select>
      </div>
    </div>
    <div class="row row-cols-2 align-items-center" v-if="dataPpdb">
      <label class="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-form-label hstack gap-1 justify-content-between pe-0">
        <span>Jumlah siswa diterima</span><span>:</span>
      </label>
      <div class="col-auto">
        <input type="text" readonly class="form-control-plaintext form-control-sm"
          :value="dataPpdb.siswa_diterima || '-'">
      </div>
    </div>
    <div class="row row-cols-2 align-items-center mb-2" v-if="dataPpdb">
      <label class="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-form-label hstack gap-1 justify-content-between pe-0">
        <span>Jumlah data diimpor</span><span>:</span>
      </label>
      <div class="col-auto">
        <input type="text" readonly class="form-control-plaintext form-control-sm"
          :value="dataPpdb.data_ditarik || '-'">
      </div>
    </div>
    <button class="btn btn-secondary px-4"
      :disabled="dataPpdb && (dataPpdb.siswa_diterima == 0 || dataPpdb.siswa_diterima == dataPpdb.data_ditarik)"
      @click="confirmImport">Impor Data</button>
  </div>
</template>

<script>
export default {
  name: 'ImporPpdb',
  data() {
    return {
      listTa: [],
      ta: null,
      dataPpdb: null
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    ta() {
      this.getDataPpdb();
    }
  },
  mounted() {
    this.getListTa();
  },
  beforeUnmount() {
  },
  methods: {
    async getListTa() {
      try {
        const response = await this.axios.post('/kurikulum/tahun-ajaran/list', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listTa = response.data.tahunAjaran;
          this.ta = this.listTa.filter(t =>
            t.ppdb_aktif == 1
          )[0];
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataPpdb() {
      try {
        const response = await this.axios.post('/kesiswaan/data/ppdb', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataPpdb = response.data.dataPpdb;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmImport() {
      if (this.dataPpdb.siswa_diterima == 0) {
        this.$swal({
          title: 'Perhatian',
          text: 'Data PPDB masih kosong!',
          icon: 'info',
          showConfirmButton: true,
        });
      } else if (this.dataPpdb.siswa_diterima == this.dataPpdb.data_ditarik) {
        this.$swal({
          title: 'Perhatian',
          text: 'Semua data sudah diimpor!',
          icon: 'info',
          showConfirmButton: true,
        });
      } else {
        this.$swal({
          title: 'Konfirmasi',
          html: `<span class="h5">Anda yakin ingin mengimpor seluruh data PPDB Tahun Ajaran
          <strong>${this.ta.nama}</strong>?</span>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya, Impor',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.imporPpdb();
          }
        });
      }
    },
    async imporPpdb() {
      try {
        const response = await this.axios.post('/kesiswaan/data/impor-ppdb', {
          tahun_ajaran_id: this.ta.tahun_ajaran_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.getDataPpdb();
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    }
  }
}
</script>

<style></style>