import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import JsonCSV from "vue-json-csv";
import bootstrapTooltip from "./components/bootstrapTooltip";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-admin.com:3001";
// app.config.globalProperties.$auth = "http://auth.com:3000";
// app.config.globalProperties.$sso = "http://sso.com:200";
// app.config.globalProperties.$appUrl = "http://admin.com:201";
// app.config.globalProperties.$appId = "5e7f2777-10cd-11ef-801e-ac120340b234";

axios.defaults.baseURL = "http://backend-admin.local";
app.config.globalProperties.$auth = "http://auth.local";
app.config.globalProperties.$sso = "http://sso.local";
app.config.globalProperties.$appUrl = "http://admin.local";
app.config.globalProperties.$appId = "0e7747b3-30b6-11ef-9288-d89ef328bfa0";

// axios.defaults.baseURL = "https://localbackend-admin.sitepgri.com";
// app.config.globalProperties.$auth = "https://localauth.sitepgri.com";
// app.config.globalProperties.$sso = "https://sso.sitepgri.com";
// app.config.globalProperties.$appUrl = "https://admin.sitepgri.com";
// app.config.globalProperties.$appId = "0ed2bf3d-3a98-11ee-a779-ac120340b234";

let isAuthenticated = false;
let isForbidden = true;

router.beforeEach((to, from, next) => {
    if (isAuthenticated) {
        if (to.name === "forbidden") {
            next();
        } else if (isForbidden) {
            next("/forbidden");
        } else {
            const user = store.getters.getUserData;
            let allowed = [];
            if (to.meta.pengguna) {
                allowed = ["it", "admin-pengguna"];
            } else if (to.meta.kurikulum) {
                allowed = ["it", "kurikulum"];
            } else if (to.meta.kesiswaan) {
                allowed = ["it", "kesiswaan"];
            }
            if (!allowed.length) {
                next();
            } else if (
                String(user.ket)
                    .split(",")
                    .some((k) => {
                        return allowed.includes(k);
                    })
            ) {
                next();
            } else {
                next("/forbidden" + to.path);
            }
        }
    } else {
        const token = store.getters.getToken;
        if (to.name === "setToken" && to.params.token) {
            store.dispatch("updateToken", to.params.token);
            store
                .dispatch("authenticateUser", app.config.globalProperties.$auth)
                .then(() => {
                    isAuthenticated = true;
                    const user = store.getters.getUserData;
                    if (user.role === "admin") {
                        isForbidden = false;
                        next("/");
                    } else {
                        isForbidden = true;
                        next("/forbidden");
                    }
                })
                .catch((err) => {
                    isAuthenticated = false;
                    isForbidden = true;
                    console.error("Failed to login with token:", err);
                    redirectToSSO();
                });
        } else if (token) {
            store
                .dispatch("authenticateUser", app.config.globalProperties.$auth)
                .then(() => {
                    isAuthenticated = true;
                    const user = store.getters.getUserData;
                    if (user.role === "admin") {
                        isForbidden = false;
                        const user = store.getters.getUserData;
                        let allowed = [];
                        if (to.meta.pengguna) {
                            allowed = ["it", "admin-pengguna"];
                        } else if (to.meta.kurikulum) {
                            allowed = ["it", "kurikulum"];
                        } else if (to.meta.kesiswaan) {
                            allowed = ["it", "kesiswaan"];
                        }
                        if (!allowed.length) {
                            next();
                        } else if (
                            String(user.ket)
                                .split(",")
                                .some((k) => {
                                    return allowed.includes(k);
                                })
                        ) {
                            next();
                        } else {
                            next("/forbidden" + to.path);
                        }
                    } else {
                        isForbidden = true;
                        next("/forbidden");
                    }
                })
                .catch((err) => {
                    isAuthenticated = false;
                    isForbidden = true;
                    console.error("Failed to login with token:", err);
                    redirectToSSO();
                });
        } else {
            redirectToSSO();
        }
    }
});

function redirectToSSO() {
    window.location.href =
        app.config.globalProperties.$sso +
        "/" +
        app.config.globalProperties.$appId;
}

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.component("v-select", vSelect);
app.component("downloadCsv", JsonCSV);
app.directive("tooltip", bootstrapTooltip);

app.mount("#app");
