<template>
  <div ref="myElement">
    <div id="cetak">
      <h6 class="text-center">Data Pengisian Buku Tamu SMK PGRI 1 KEDIRI</h6>
      <table class="table table-bordered border-secondary table-sm small" style="font-size: 10pt;">
        <thead class="text-center align-middle small">
          <th>No.</th>
          <th>Petugas</th>
          <th>Nama Tamu</th>
          <th>Jenis Instansi</th>
          <th>Nama Instansi</th>
          <th>Alamat</th>
          <th>Kontak</th>
          <th>Bagian yang dituju</th>
          <th>Keperluan</th>
          <th>Foto</th>
          <th>Tanda Tangan</th>
          <th>Waktu</th>
        </thead>
        <tbody class="small">
          <tr v-for="d, index in bukuTamu" :key="d">
            <td class="text-center" style="width: 3%;">{{ index+=1 }}</td>
            <td style="width: 8%;">{{ d.nama_admin }}</td>
            <td style="width: 10%;">{{ d.nama_tamu }}</td>
            <td style="width: 7%;">{{ d.asal }}</td>
            <td style="width: 11%;">{{ d.keterangan }}</td>
            <td style="width: 12%;">{{ d.alamat }}</td>
            <td class="text-center" style="width: 8%;">{{ d.kontak }}</td>
            <td style="width: 8%;">{{ d.tujuan }}</td>
            <td style="width: 10%;">{{ d.keperluan }}</td>
            <td class="text-center">
              <img :src="d.foto" alt="" style="max-width: 30mm; max-height: 40mm;">
            </td>
            <td class="text-center">
              <img :src="d.ttd" alt="" style="width: 15mm;">
            </td>
            <td class="text-center" style="width: 8%;">{{ d.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CetakBukuTamu",
  data() {
    return {
      bukuTamu: []
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    taAktif() {
      return this.$store.getters.getTahunAjaran
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      loader: 'spinner'
    });
    this.getDataBukuTamu();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  methods: {
    async getDataBukuTamu() {
      try {
        const response = await this.axios.post('/tamu/all', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.bukuTamu = response.data.buku_tamu;
          for (const t of this.bukuTamu) {
            t.created_at = new Date(t.created_at).toLocaleString('id-ID', {
              dateStyle: 'medium',
              timeStyle: 'short'
            });
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.cetak();
      }, 500)
    },
    cetak() {
      let printContents = this.$refs.myElement.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.close();
    }
  }
}
</script>

<style scoped>
#cetak {
  font-family: 'Trebuchet MS', sans-serif;
}
</style>