<template>
  <h4>URL Shortener</h4>
  <div class="card shadow">
    <div class="card-header vl-parent" ref="formCreate">
      <div class="col-md-10 col-lg-8 col-xl-6 col-sm-12">
        <form @submit.prevent="simpanData" class="needs-validation" novalidate>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label-sm">URL Khusus</label>
            <div class="col-sm-9">
              <div class="input-group input-group-sm has-validation">
                <span class="input-group-text pe-1 rounded-end-0 shadow-sm">{{ main_url }}</span>
                <input type="text" class="form-control ps-1 shadow-sm" placeholder="URL Khusus, contoh: custom-url"
                  v-model="short_url" required :pattern="short_pattern" @keyup="cekKetersediaan">
                <div class="invalid-feedback" v-if="short_url">{{ short_info }}</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label-sm">URL Asli</label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm shadow-sm"
                placeholder="URL Asli, contoh: https://www.example.com" v-model="long_url" required pattern="[^\s]+"
                @keyup="cekUrl">
              <div class="invalid-feedback" v-if="long_url">URL tidak boleh terdiri dari karakter spasi.</div>
            </div>
          </div>
          <div class="row mb-2">
            <label class="col-sm-3 col-form-label-sm">Deskripsi</label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm shadow-sm" placeholder="Deskripsi URL"
                v-model="deskripsi">
            </div>
          </div>
          <button type="submit" class="btn btn-sm btn-secondary d-flex ms-auto shadow-sm">Buat Shorten Link</button>
        </form>
      </div>
    </div>
    <div class="card-body">
      <button v-if="tercentang.length" class="btn btn-sm btn-danger shadow-sm d-flex ms-auto mb-2"
        @click="confirmDeleteSelected">Hapus data terpilih
      </button>
      <div class="table-responsive shadow vl-parent" ref="table">
        <table class="table table-sm mb-0">
          <thead class="table-secondary align-middle">
            <tr>
              <th>#</th>
              <th class="position-sticky start-0">
                <input id="cari" type="search" class="form-control form-control-sm shadow-sm" placeholder="Short URL"
                  v-model="key" @keyup="search" @search="search">
              </th>
              <th>Deskripsi</th>
              <th class="align-middle">
                <div class="row">
                  <label class="col-md-5 col-lg-4 col-form-label-sm text-nowrap">Dibuat</label>
                  <div class="col-md-7 col-lg-8" v-if="user.ket == 'it'">
                    <select class="form-select form-select-sm" v-model="admin_id_selected" @change="getDataShorts">
                      <option value="">Semua</option>
                      <option v-for="a in dataAdmin" :key="a" :value="a.admin_id">{{ a.nama }}</option>
                    </select>
                  </div>
                </div>
              </th>
              <th>Diperbarui</th>
              <th>
                <div class="d-flex justify-content-between">
                  Tindakan
                  <input class="form-check-input" type="checkbox" v-model="semuaTercentang" @click="centangSemua">
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="links.length">
            <tr v-for="d, index in links" :key="d">
              <td>{{ index+=1 + pageOffset }}</td>
              <td class="position-sticky start-0">
                <div class="d-flex justify-content-between">
                  <a :href="d.long_url" target="blank">{{ d.short_url }}</a>
                  <div class="d-block ms-1">
                    <button class="btn btn-sm btn-outline-secondary d-flex ms-sm-auto" @click="salinShort(d)"
                      v-tooltip="'Salin URL'">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-clipboard"
                        viewBox="0 0 16 16">
                        <path
                          d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path
                          d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </td>
              <td>{{ d.deskripsi }}</td>
              <td>
                <strong>{{ d.creator }}</strong><br />
                <small class="fst-italic">{{ d.created_at.toLocaleString('id-ID') }}</small>
              </td>
              <td>
                <div v-if="d.editor">
                  <strong>{{ d.editor }}</strong><br />
                  <em>{{ d.updated_at.toLocaleString('id-ID') }}</em>
                </div>
                <span v-else>-</span>
              </td>
              <td>
                <div class="d-flex justify-content-between">
                  <div class="hstack gap-1 me-1">
                    <button class="btn btn-sm btn-outline-dark d-flex cooltip" @click="generateQRCode(d)"
                      data-bs-toggle="modal" data-bs-target="#qrCode" v-tooltip="'QR Code'">
                      <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor" class="bi bi-qr-code"
                        viewBox="0 0 16 16">
                        <path d="M2 2h2v2H2V2Z" />
                        <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                        <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                        <path
                          d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                        <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)" data-bs-toggle="modal"
                      data-bs-target="#editData" v-tooltip="'Edit'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                    <div class="vr"></div>
                    <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)" v-tooltip="'Hapus'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                        viewBox="0 0 16 16">
                        <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </button>
                  </div>
                  <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatu(d)">
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="7">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="links.length" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + links.length }} dari total {{ count }} data
      </span>
    </div>
    <div v-if="links.length" class="card-footer">
      <div class="row row-cols-auto justify-content-sm-between justify-content-center">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in  pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
          (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content vl-parent" ref="formEdit">
          <form @submit.prevent="updateData" class="needs-validation-edit" novalidate>
            <div class="modal-header">
              <legend class="modal-title">Edit URL</legend>
              <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-2">
                <label class="col-sm-3 col-form-label">URL Khusus</label>
                <div class="col-sm-9">
                  <div class="input-group has-validation">
                    <span class="input-group-text pe-1 rounded-end-0 shadow-sm">sitepgri.com/</span>
                    <input type="text" class="form-control ps-1 shadow-sm" placeholder="URL Khusus, contoh: custom-url"
                      v-model="formEdit.short_url" required :pattern="short_patternEdit" @keyup="cekKetersediaanEdit">
                    <div class="invalid-feedback" v-if="formEdit.short_url">{{ short_infoEdit }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-3 col-form-label">URL Asli</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control shadow-sm"
                    placeholder="URL Asli, contoh: https://www.example.com" v-model="formEdit.long_url" required
                    pattern="[^\s]+" @keyup="cekUrlEdit">
                  <div class="invalid-feedback" v-if="formEdit.long_url">URL tidak boleh terdiri dari karakter spasi.
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-3 col-form-label">Deskripsi</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control shadow-sm" placeholder="Deskripsi URL"
                    v-model="formEdit.deskripsi">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary shadow-sm">Perbarui</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="qrCode" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <legend class="modal-title">QR Code</legend>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body text-center">
            <canvas class="border border-1" ref="qrCodeCanvas"></canvas>
          </div>
          <div class="modal-footer">
            <a ref="downloadLink" class="btn btn-success">Unduh QR Code</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div id="urlCopied" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-body">
        Short URL berhasil disalin.
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import { debounce } from 'lodash';
import QRCode from 'qrcode';
import { Toast } from 'bootstrap';
export default {
  name: 'ShortView',
  data() {
    return {
      insertListener: (d) => {
        const data = JSON.parse(d);
        if (this.it) {
          if (this.admin_id_selected) {
            if (this.admin_id_selected == data.admin_id) {
              this.getDataShorts();
            }
          } else {
            this.getDataShorts();
          }
        } else if (this.user.admin_id == data.admin_id) {
          this.getDataShorts();
        }
      },
      updateListener: (d) => {
        const data = JSON.parse(d);
        if (Object(this.formEdit).short_id == data.short_id) {
          document.getElementById('tutupFormEdit').click();
        }
        if (this.it) {
          if (this.admin_id_selected) {
            if (this.admin_id_selected == data.admin_id) {
              this.getDataShorts();
            }
          } else {
            this.getDataShorts();
          }
        } else if (this.user.admin_id == data.admin_id) {
          this.getDataShorts();
        }
      },
      deleteListener: (d) => {
        const data = JSON.parse(d);
        if (data.list_short_id) {
          if (data.list_short_id.includes(Object(this.formEdit).short_id)) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.it) {
            if (this.admin_id_selected) {
              if (data.list_admin_id.some((a) => {
                return a.created_by == this.admin_id_selected
              })) {
                if (this.pageCurrent == this.pageCount && this.links.length <= data.list_short_id.length) {
                  if (this.pageCurrent == 1) {
                    this.getDataShorts();
                  } else {
                    this.pageCurrent = 1;
                  }
                } else {
                  this.getDataShorts();
                }
              }
            } else {
              if (this.pageCurrent == this.pageCount && this.links.length <= data.list_short_id.length) {
                if (this.pageCurrent == 1) {
                  this.getDataShorts();
                } else {
                  this.pageCurrent = 1;
                }
              } else {
                this.getDataShorts();
              }
            }
          } else if (data.list_admin_id.some((a) => {
            return a.created_by == this.user.admin_id
          })) {
            if (this.pageCurrent == this.pageCount && this.links.length <= data.list_short_id.length) {
              if (this.pageCurrent == 1) {
                this.getDataShorts();
              } else {
                this.pageCurrent = 1;
              }
            } else {
              this.getDataShorts();
            }
          }
        } else if (data.admin_id) {
          if (Object(this.formEdit).short_id == data.short_id) {
            document.getElementById('tutupFormEdit').click();
          }
          if (this.it) {
            if (this.admin_id_selected) {
              if (this.admin_id_selected == data.admin_id) {
                if (this.pageCurrent == this.pageCount && this.links.length == 1) {
                  if (this.pageCurrent == 1) {
                    this.getDataShorts();
                  } else {
                    this.pageCurrent = 1;
                  }
                } else {
                  this.getDataShorts();
                }
              }
            } else {
              if (this.pageCurrent == this.pageCount && this.links.length == 1) {
                if (this.pageCurrent == 1) {
                  this.getDataShorts();
                } else {
                  this.pageCurrent = 1;
                }
              } else {
                this.getDataShorts();
              }
            }
          } else if (this.user.admin_id == data.admin_id) {
            if (this.pageCurrent == this.pageCount && this.links.length == 1) {
              if (this.pageCurrent == 1) {
                this.getDataShorts();
              } else {
                this.pageCurrent = 1;
              }
            } else {
              this.getDataShorts();
            }
          }
        }
      },
      userListener: (d) => {
        const data = JSON.parse(d);
        if (data.role == 'admin') {
          if (this.it) {
            this.getListAdmins();
          }
        }
      },
      main_url: 'sitepgri.com/',
      short_url: null,
      long_url: null,
      deskripsi: null,
      tersedia: true,
      sesuai: false,
      short_pattern: "",
      short_info: "",
      tersediaEdit: true,
      sesuaiEdit: true,
      short_patternEdit: "",
      short_infoEdit: "",
      links: [],
      dataAdmin: [],
      admin_id_selected: "",
      key: "",
      arrayLimit: [10, 25, 50, 100],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      selectedData: {},
      formEdit: {},
      tercentang: [],
      semuaTercentang: false,
      it: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataShorts();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataShorts();
      } else {
        this.pageCurrent = 1;
      }
    },
    admin_id_selected() {
      if (this.pageCurrent == 1) {
        this.getDataShorts();
      } else {
        this.pageCurrent = 1;
      }
    }
  },
  mounted() {
    this.it = String(this.user.ket).split(",").some((k) => {
      return ["it"].includes(k);
    });
    this.getDataShorts();
    if (this.it) {
      this.getListAdmins();
    }
    WebSocketService.on("inserted-link", this.insertListener);
    WebSocketService.on("updated-link", this.updateListener);
    WebSocketService.on("deleted-link", this.deleteListener);
    WebSocketService.on("inserted-user", this.userListener);
    WebSocketService.on("updated-user", this.userListener);
    WebSocketService.on("deleted-user", this.userListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-link", this.insertListener);
    WebSocketService.off("updated-link", this.updateListener);
    WebSocketService.off("deleted-link", this.deleteListener);
    WebSocketService.off("inserted-user", this.userListener);
    WebSocketService.off("updated-user", this.userListener);
    WebSocketService.off("deleted-user", this.userListener);
  },
  methods: {
    async getListAdmins() {
      try {
        const response = await this.axios.post('/user/list/admin', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataAdmin = response.data.admins;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataShorts() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      try {
        const response = await this.axios.post('/short/tampilkan', {
          admin_id: this.admin_id_selected,
          key: this.key,
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.links = response.data.links;
          for (const l of this.links) {
            l.created_at = new Date(l.created_at);
            l.updated_at = new Date(l.updated_at);
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          this.tercentang = [];
          this.semuaTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    search: debounce(function () {
      if (this.pageCurrent == 1) {
        this.getDataShorts();
      } else {
        this.pageCurrent = 1;
      }
    }, 500),
    cekKetersediaan: debounce(async function () {
      if (this.short_url) {
        try {
          const response = await this.axios.post('/short/cek', {
            short_url: this.short_url
          }, {
            headers: { Authorization: this.token }
          });
          if (response) {
            if (response.data.length) {
              this.short_pattern = "";
              this.short_info = "URL sudah digunakan."
              this.tersedia = false;
            } else {
              this.short_pattern = "[^ ]+";
              this.short_info = "URL tidak boleh terdiri dari karakter spasi."
              this.tersedia = true;
            }
            this.cekUrl();
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        }
      }
    }, 500),
    cekUrl() {
      const formatUrl = /\s/g;
      const spasiShort = new String(this.short_url).match(formatUrl);
      const spasiLong = new String(this.long_url).match(formatUrl);
      if (this.short_url && this.long_url) {
        if (spasiShort || spasiLong) {
          this.sesuai = false;
        } else {
          this.sesuai = true;
        }
      } else {
        this.sesuai = false;
      }
    },
    async simpanData() {
      if (this.sesuai && this.tersedia) {
        let memuat = this.$loading.show({
          container: this.$refs.formCreate,
          loader: 'spinner'
        });
        try {
          const response = await this.axios.post('/short/tambah', {
            short_url: this.short_url,
            long_url: this.long_url,
            deskripsi: this.deskripsi || '-'
          }, {
            headers: { Authorization: this.token }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              const forms = document.querySelectorAll('.needs-validation')
              Array.from(forms).forEach(form => {
                form.classList.remove('was-validated')
              });
              this.short_url = null;
              this.short_url = null;
              this.long_url = null;
              this.deskripsi = null;
              this.tersedia = true;
              this.sesuai = false;
              this.short_pattern = "";
              this.short_info = "";
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silakan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      } else {
        const forms = document.querySelectorAll('.needs-validation')
        Array.from(forms).forEach(form => {
          form.classList.add('was-validated')
        });
      }
    },
    cekKetersediaanEdit: debounce(async function () {
      if (this.formEdit.short_url) {
        if (this.formEdit.short_url == this.selectedData.short_url) {
          this.short_patternEdit = "[^ ]+";
          this.short_infoEdit = "URL tidak boleh terdiri dari karakter spasi."
          this.tersediaEdit = true;
        } else {
          try {
            const response = await this.axios.post('/short/cek', {
              short_url: this.formEdit.short_url
            }, {
              headers: { Authorization: this.token }
            });
            if (response) {
              if (response.data.length) {
                this.short_patternEdit = "";
                this.short_infoEdit = "URL sudah digunakan."
                this.tersediaEdit = false;
              } else {
                this.short_patternEdit = "[^ ]+";
                this.short_infoEdit = "URL tidak boleh terdiri dari karakter spasi."
                this.tersediaEdit = true;
              }
              this.cekUrlEdit();
            }
          }
          catch (err) {
            if (err.response) {
              if (err.response.status == 401) {
                this.$swal({
                  title: 'Gagal',
                  text: 'Sesi berakhir. Silakan login ulang.',
                  icon: 'error',
                  confirmButtonText: 'Baik'
                }).then(() => {
                  this.$store.dispatch('logoutUser');
                  window.location.href = this.$sso + "/" + this.$appId;
                });
              } else {
                console.log(err);
              }
            } else {
              console.log(err)
            }
          }
        }
      }
    }, 500),
    cekUrlEdit() {
      const formatUrl = /\s/g;
      const spasiShort = new String(this.formEdit.short_url).match(formatUrl);
      const spasiLong = new String(this.formEdit.long_url).match(formatUrl);
      if (this.formEdit.short_url && this.formEdit.long_url) {
        if (spasiShort || spasiLong) {
          this.sesuaiEdit = false;
        } else {
          this.sesuaiEdit = true;
        }
      } else {
        this.sesuaiEdit = false;
      }
    },
    editData(d) {
      this.selectedData = d;
      this.formEdit = {
        ...d
      }
      const forms = document.querySelectorAll('.needs-validation-edit')
      Array.from(forms).forEach(form => {
        form.classList.remove('was-validated')
      });
    },
    async updateData() {
      if (this.sesuaiEdit && this.tersediaEdit) {
        let memuat = this.$loading.show({
          container: this.$refs.formEdit,
          loader: 'spinner'
        });
        if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
          this.$swal({
            title: 'Perhatian',
            text: 'Anda belum melakukan perubahan',
            icon: 'warning',
            confirmButtonText: 'Baik'
          });
        } else {
          const newData = {
            short_id: this.formEdit.short_id,
            short_url: this.formEdit.short_url,
            long_url: this.formEdit.long_url,
            deskripsi: this.formEdit.deskripsi || '-',
            created_by: this.formEdit.created_by
          }
          try {
            const response = await this.axios.post('/short/update', newData, {
              headers: { Authorization: this.token }
            });
            if (response.data) {
              document.getElementById('tutupFormEdit').click();
              const forms = document.querySelectorAll('.needs-validation-edit')
              Array.from(forms).forEach(form => {
                form.classList.remove('was-validated')
              });
              this.$swal({
                title: 'Sukses',
                text: response.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
          catch (err) {
            if (err.response) {
              if (err.response.status == 401) {
                this.$swal({
                  title: 'Gagal',
                  text: 'Sesi berakhir. Silakan login ulang.',
                  icon: 'error',
                  confirmButtonText: 'Baik'
                }).then(() => {
                  this.$store.dispatch('logoutUser');
                  window.location.href = this.$sso + "/" + this.$appId;
                });
              } else if (err.response.status == 403) {
                this.$swal({
                  title: 'Gagal',
                  text: 'Anda tidak memiliki akses untuk memperbarui data.',
                  icon: 'error',
                  confirmButtonText: 'Baik'
                });
              } else {
                this.$swal({
                  title: 'Gagal',
                  html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                  icon: 'error',
                  confirmButtonText: 'Baik'
                });
              }
            } else {
              console.log(err)
            }
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      } else {
        const forms = document.querySelectorAll('.needs-validation-edit')
        Array.from(forms).forEach(form => {
          form.classList.add('was-validated')
        });
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Shorten Link <code class="text-black text-bg-secondary bg-opacity-50">${d.short_url}</code> (${d.deskripsi})?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/short/hapus', {
          short_id: d.short_id,
          created_by: d.created_by
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(d) {
      const ditemukan = this.tercentang.findIndex((j) =>
        j == d.short_id
      );
      if (ditemukan >= 0) {
        this.tercentang.splice(ditemukan, 1);
      } else {
        this.tercentang.push(d.short_id);
      }
      if (this.tercentang.length == this.links.length) {
        this.semuaTercentang = true;
      } else {
        this.semuaTercentang = false;
      }
    },
    centangSemua() {
      this.tercentang = []
      if (this.semuaTercentang) {
        for (const d of this.links) {
          d.centang = false;
          this.tercentang = [];
        }
      } else {
        for (const d of this.links) {
          d.centang = true;
          this.tercentang.push(d.short_id);
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data Shoten Link terpilih?<br/>
						&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected();
        }
      });
    },
    async deleteSelected() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post("/short/hapus-multiple", {
          linkToDelete: this.tercentang,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.tercentang = [];
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    generateQRCode(d) {
      const text = d.long_url
      const canvas = this.$refs.qrCodeCanvas
      const downloadLink = this.$refs.downloadLink
      canvas.style.backgroundColor = 'transparent'

      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      QRCode.toDataURL(text, function (error, url) {
        if (error) {
          console.error(error)
        } else {
          const img = new Image()
          img.src = url
          img.onload = function () {
            canvas.width = img.width
            canvas.height = img.height + 40

            let fontSize = 42

            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, canvas.width, img.height + fontSize * 1.5)
            ctx.drawImage(img, 0, 0)

            const textToAdd = 'sitepgri.com/' + d.short_url
            const maxWidth = canvas.width - 20

            do {
              fontSize -= 2
              ctx.font = `${fontSize}px Trebuchet MS`
            } while (ctx.measureText(textToAdd).width > maxWidth)

            const x = (canvas.width - ctx.measureText(textToAdd).width) / 2
            const y = img.height + fontSize / 2
            ctx.fillStyle = 'black'
            ctx.fillText(textToAdd, x, y)

            downloadLink.download = d.deskripsi + '.png'
            downloadLink.href = canvas.toDataURL('image/png')
          }
        }
      }.bind(this))
    },
    async salinShort(d) {
      try {
        const teksToCopy = this.main_url + d.short_url;
        const tempElem = document.createElement("textarea");
        tempElem.value = teksToCopy;

        document.body.appendChild(tempElem);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);

        const infoSalin = document.getElementById("urlCopied");
        const toast = new Toast(infoSalin, {
          delay: 1500
        });
        toast.show();
      } catch (err) {
        console.error('Gagal menyalin teks ke clipboard:', err);
      }
    }
  }
}
</script>

<style></style>