import { createStore } from "vuex";
import axios from "axios";

export default createStore({
    state: {
        token: localStorage.getItem("token") || null,
        userData: null,
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getUserData(state) {
            return state.userData;
        },
    },
    mutations: {
        setToken(state, value) {
            state.token = value;
            localStorage.setItem("token", value);
        },
        setUserData(state, data) {
            state.userData = data;
            localStorage.setItem("user", JSON.stringify(data));
        },
        updateUserData(state, userData) {
            state.userData = userData;
            localStorage.setItem("user", JSON.stringify(userData));
        },
    },
    actions: {
        updateToken({ commit }, data) {
            commit("setToken", data);
        },
        authenticateUser({ commit }, auth) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        auth + "/info",
                        {},
                        { headers: { Authorization: this.state.token } }
                    )
                    .then((response) => {
                        commit("setUserData", response.data.user);
                        resolve();
                    })
                    .catch((error) => {
                        commit("setToken", null);
                        commit("setUserData", null);
                        reject(error);
                    });
            });
        },
        logoutUser({ commit }) {
            localStorage.removeItem("token");
            commit("setUserData", null);
            commit("setToken", null);
        },
    },
    modules: {},
});
