import { createRouter, createWebHistory } from "vue-router";
import SetToken from "../components/SetToken";
import HomeView from "../views/HomeView";
import UserView from "../views/UserView";
import KurikulumView from "../views/KurikulumView";
import KesiswaanView from "../views/KesiswaanView";
import ShortView from "../views/ShortView";
import BukuTamuView from "../views/BukuTamuView";
import ProfilView from "../views/ProfilView";
import GantiSandiView from "../views/GantiSandiView";
import ForbiddenView from "../views/ForbiddenView";
import NotFound from "../components/NotFound";
import CetakBukuTamu from "@/views/CetakBukuTamu";

const routes = [
    {
        path: "/auth/:token?",
        name: "setToken",
        component: SetToken,
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/users/:role?",
        name: "users",
        component: UserView,
        meta: { pengguna: true },
    },
    {
        path: "/kurikulum/:data?",
        name: "kurikulum",
        component: KurikulumView,
        meta: { kurikulum: true },
    },
    {
        path: "/kesiswaan/:data?",
        name: "kesiswaan",
        component: KesiswaanView,
        meta: { kesiswaan: true },
    },
    {
        path: "/shorts",
        name: "shorts",
        component: ShortView,
    },
    {
        path: "/tamu",
        name: "buku-tamu",
        component: BukuTamuView,
    },
    {
        path: "/tamu/cetak",
        name: "cetak-buku-tamu",
        component: CetakBukuTamu,
    },
    {
        path: "/profil",
        name: "profil",
        component: ProfilView,
    },
    {
        path: "/ganti-sandi",
        name: "ganti-sandi",
        component: GantiSandiView,
    },
    {
        path: "/forbidden/:halaman(.*)?",
        name: "forbidden",
        component: ForbiddenView,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
