<template>
    <h4>Buku Tamu</h4>
    <div class="card shadow">
        <div class="card-header">
            <h5 class="card-title">Filter Data</h5>
            <div class="row g-3">
                <div class="col-lg-3 col-md-4">
                    <label for="filterAdmin" class="form-label">Petugas</label>
                    <v-select id="filterAdmin" class="form-control form-control-sm p-0 border-0" v-model="admin"
                        placeholder="Petugas Admin" :options="listAdmin" label="nama">
                        <template #no-options>
                            Data tidak ditemukan.
                        </template>
                    </v-select>
                </div>
                <div class="col-lg-3 col-md-4">
                    <label for="filterAsal" class="form-label">Instansi</label>
                    <v-select id="filterAsal" class="form-control form-control-sm p-0 border-0" v-model="asal"
                        placeholder="Jenis Instansi" :options="listAsal">
                        <template #no-options>
                            Data tidak ditemukan.
                        </template>
                    </v-select>
                </div>
                <div class="col-lg-3 col-md-4">
                    <label for="filterTujuan" class="form-label">Tujuan</label>
                    <v-select id="filterTujuan" class="form-control form-control-sm p-0 border-0" v-model="tujuan"
                        placeholder="Bagian yang dituju" :options="listTujuan">
                        <template #no-options>
                            Data tidak ditemukan.
                        </template>
                    </v-select>
                </div>
                <div class="col-auto ms-auto mt-lg-auto mt-md-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">Token Tamu :</span>
                        <span class="input-group-text fw-bold">{{ tokenTamu || '-' }}</span>
                        <button class="btn btn-outline-secondary" type="button" @click="generateToken"
                            :disabled="memuatToken">
                            <span v-if="memuatToken" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span v-else>Buat</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="mb-2 d-flex" v-if="bukuTamu.length">
                <button type="button" class="btn btn-sm btn-success shadow-sm ms-auto" @click="cetak">Unduh
                    Buku Tamu</button>
            </div>
            <div class="table-responsive shadow vl-parent" ref="table">
                <table class="table table-sm mb-0" id="dataBukuTamu">
                    <thead class="table-secondary align-middle">
                        <tr>
                            <th>#</th>
                            <th>Admin</th>
                            <th class="position-sticky start-0">Nama</th>
                            <th>Jenis Instansi</th>
                            <th>Nama Instansi</th>
                            <th>Alamat</th>
                            <th>Kontak</th>
                            <th>Bagian yang dituju</th>
                            <th>Keperluan</th>
                            <th>Foto</th>
                            <th>Tanda Tangan</th>
                            <th>Waktu</th>
                            <th v-if="it">Tindakan</th>
                        </tr>
                    </thead>
                    <tbody v-if="bukuTamu.length">
                        <tr v-for="d, index in bukuTamu" :key="d">
                            <td>{{ index+=1 + pageOffset }}</td>
                            <td>{{ d.nama_admin }}</td>
                            <td class="position-sticky start-0">{{ d.nama_tamu }}</td>
                            <td>{{ d.asal }}</td>
                            <td>{{ d.keterangan }}</td>
                            <td>{{ d.alamat }}</td>
                            <td>{{ d.kontak }}</td>
                            <td>{{ d.tujuan }}</td>
                            <td>{{ d.keperluan }}</td>
                            <td>
                                <img :src="d.foto" alt="" style="height: 50px;" @click="selectedData = d"
                                    data-bs-toggle="modal" data-bs-target="#previewImage">
                            </td>
                            <td>
                                <img :src="d.ttd" alt="" style="height: 50px;" @click="selectedData = d"
                                    data-bs-toggle="modal" data-bs-target="#previewSignature">
                            </td>
                            <td>{{ d.created_at }}</td>
                            <td v-if="it">
                                <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)"
                                    v-tooltip="'Hapus'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center fst-italic py-3" colspan="13">Data tidak ditemukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span v-if="bukuTamu.length" class="small text-muted fst-italic">
                Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + bukuTamu.length }} dari total {{ count }} data
            </span>
        </div>
        <div v-if="bukuTamu.length" class="card-footer">
            <div class="row row-cols-auto justify-content-sm-between justify-content-center">
                <div class="row row-cols-auto">
                    <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
                    <div class="col-auto ps-1">
                        <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
                            <option v-for="l in arrayLimit" :key="l" :value="l">
                                {{ l }} baris
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-auto">
                    <ul class="pagination pagination-sm shadow mb-0">
                        <li class="page-item">
                            <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
                        </li>
                        <li class="page-item" v-for="p in  pageCount " :key="p">
                            <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
                            <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1"
                                class="page-link" @click="pageCurrent = p">{{ p }}</button>
                            <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                        (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                                @click="pageCurrent = p">{{ p }}</button>
                            <button
                                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                                class="page-link" @click="pageCurrent = p">{{ p }}</button>
                            <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                        (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                        (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                                @click="pageCurrent = p">...</button>
                        </li>
                        <li class="page-item">
                            <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="previewImage" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <legend class="modal-title">{{ selectedData.nama_tamu + ' - ' + selectedData.keterangan }}</legend>
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <img v-if="selectedData.foto" :src="selectedData.foto" alt="" class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="previewSignature" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <legend class="modal-title">{{ selectedData.nama_tamu + ' - ' + selectedData.keterangan }}</legend>
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <img v-if="selectedData.ttd" :src="selectedData.ttd" alt="" class="w-100">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
    name: 'BukuTamuView',
    data() {
        return {
            tokenUsedListener: (d) => {
                if (this.tokenTamu == d) {
                    this.tokenTamu = null;
                }
            },
            insertListener: () => {
                this.getDataBukuTamu();
            },
            deleteListener: () => {
                if (this.pageCurrent == this.pageCount && this.bukuTamu.length == 1) {
                    if (this.pageCurrent == 1) {
                        this.getDataBukuTamu();
                    } else {
                        this.pageCurrent = 1;
                    }
                } else {
                    this.getDataBukuTamu();
                }
            },
            listAdmin: [],
            listAsal: [
                "Organisasi", "Perusahaan", "Lembaga", "Masyarakat", "Instansi Pendidikan", "Lainnya"
            ],
            listTujuan: [
                "Kepala Sekolah", "Kurikulum", "Kesiswaan", "Humas", "Sarpras", "Bendahara", "Tata Usaha", "Lainnya"
            ],
            admin: null,
            asal: null,
            tujuan: null,
            tokenTamu: null,
            bukuTamu: [],
            arrayLimit: [10, 25, 50, 100, 250],
            count: 0,
            pageCurrent: 1,
            pageOffset: 0,
            pageLimit: 10,
            pageCount: 0,
            memuatToken: false,
            selectedData: {},
            it: false
        }
    },
    computed: {
        token() {
            return this.$store.getters.getToken
        },
        user() {
            return this.$store.getters.getUserData;
        },
    },
    mounted() {
        this.it = String(this.user.ket).split(",").some((k) => {
            return ["it"].includes(k);
        });
        WebSocketService.on("token-digunakan", this.tokenUsedListener);
        WebSocketService.on("inserted-buku-tamu", this.insertListener);
        WebSocketService.on("deleted-buku-tamu", this.insertListener);
        this.getListAdmins();
        this.getDataBukuTamu();
    },
    beforeUnmount() {
        WebSocketService.off("token-digunakan", this.tokenUsedListener);
        WebSocketService.off("inserted-buku-tamu", this.insertListener);
        WebSocketService.off("deleted-buku-tamu", this.insertListener);
    },
    watch: {
        admin() { this.getDataBukuTamu(); },
        asal() { this.getDataBukuTamu(); },
        tujuan() { this.getDataBukuTamu(); },
        pageCurrent() {
            this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
            this.getDataBukuTamu();
        },
        pageLimit() {
            if (this.pageCurrent == 1) {
                this.getDataBukuTamu();
            } else {
                this.pageCurrent = 1;
            }
        },
    },
    methods: {
        async getListAdmins() {
            try {
                const response = await this.axios.post('/user/list/admin', {}, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.listAdmin = response.data.admins;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
        },
        async getDataBukuTamu() {
            let memuat = this.$loading.show({
                container: this.$refs.table,
                loader: 'dots'
            });
            try {
                const response = await this.axios.post('/tamu/kontrol', {
                    admin_id: this.admin ? this.admin.admin_id : null,
                    asal: this.asal,
                    tujuan: this.tujuan,
                    limit: this.pageLimit,
                    offset: this.pageOffset
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.bukuTamu = response.data.buku_tamu;
                    for (const t of this.bukuTamu) {
                        t.created_at = new Date(t.created_at).toLocaleString('id-ID', {
                            dateStyle: 'medium',
                            timeStyle: 'short'
                        });
                    }
                    this.count = response.data.count;
                    this.pageCount = Math.ceil(this.count / this.pageLimit);
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
        async generateToken() {
            this.memuatToken = true;
            let newToken = null;
            try {
                const response = await this.axios.post('/tamu/buat-token', {
                    limit: this.pageLimit,
                    offset: this.pageOffset
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    newToken = response.data.new_token;
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else {
                        console.log(err);
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                this.memuatToken = false;
                this.tokenTamu = newToken;
            }, 250);
        },
        cetak() {
            const routeCetak = this.$router.resolve({
                path: '/tamu/cetak'
            });
            window.open(routeCetak.href, '_blank');
        },
        async unduhPDF() {
        },
        confirmDelete(d) {
            this.$swal({
                title: 'Konfirmasi',
                html: `<span class="h5">Anda yakin ingin menghapus data buku tamu <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_tamu}</code> (${d.asal})?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Ya, Hapus',
                cancelButtonText: 'Batal',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hapusData(d);
                }
            });
        },
        async hapusData(d) {
            let memuat = this.$loading.show({
                opacity: 0,
                loader: 'spinner'
            });
            try {
                const response = await this.axios.post('/tamu/hapus', {
                    buku_tamu_id: d.buku_tamu_id,
                }, {
                    headers: { Authorization: this.token }
                });
                if (response.data) {
                    this.$swal({
                        title: 'Sukses',
                        text: response.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
            catch (err) {
                if (err.response) {
                    if (err.response.status == 401) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Sesi berakhir. Silakan login ulang.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        }).then(() => {
                            this.$store.dispatch('logoutUser');
                            window.location.href = this.$sso + "/" + this.$appId;
                        });
                    } else if (err.response.status == 403) {
                        this.$swal({
                            title: 'Gagal',
                            text: 'Anda tidak memiliki akses untuk menghapus data.',
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    } else {
                        this.$swal({
                            title: 'Gagal',
                            html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                            icon: 'error',
                            confirmButtonText: 'Baik'
                        });
                    }
                } else {
                    console.log(err)
                }
            }
            setTimeout(() => {
                memuat.hide();
            }, 250);
        },
    }
}
</script>

<style></style>