<template>
  <div class="card-body">
    <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
      <div class="col-auto p-0 d-inline-flex">
        <h5 class="my-auto">Anggota Rombongan Belajar</h5>
      </div>
      <div class="col-auto p-0 ms-auto">
        <select class="form-select form-select-sm shadow-sm" v-model="semester">
          <option disabled value="">-- Pilih Semester --</option>
          <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
        </select>
      </div>
    </div>
    <div v-if="dataRombel.length" class="accordion shadow rounded vl-parent" ref="accordion">
      <div v-for="d, index in dataRombel" :key="d" class="accordion-item">
        <h6 class="accordion-header">
          <button class="accordion-button collapsed py-2" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#r' + d.rombel_id" aria-expanded="false" :aria-controls="d.rombel_id"
            @click="d.anggotaRombel ? '' : getDataAnggotaRombel(d)">
            <div class="row justify-content-between w-100 gap-3">
              <div class="col-4">{{ d.nama }}</div>
              <div class="col">
                <transition name="slide-up" mode="out-in">
                  <strong class="d-inline-block my-0 pe-1" :key="d.jumlah">
                    {{ d.jumlah || "-" }}
                  </strong>
                </transition>
                <small class="text-muted">siswa</small>
              </div>
            </div>
          </button>
        </h6>
        <div :id="'r' + d.rombel_id" class="accordion-collapse collapse">
          <div class="accordion-body bg-light x-5" :class="{ 'rounded-bottom': index == dataRombel.length - 1 }">
            <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
              <div class="col-auto p-0 d-inline-flex">
                <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
                  data-bs-target="#tambahAnggota" @click="selectedRombel = d">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>Tambahkan Anggota Rombel {{ d.nama }}
                </button>
              </div>
              <div class="col-auto p-0">
                <button type="button" class="btn btn-sm btn-success hstack gap-1 shadow-sm" data-bs-toggle="modal"
                  data-bs-target="#nextSemester" @click="fromRombel = d">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-graph-up-arrow"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                  </svg>Lanjut Semester
                </button>
              </div>
            </div>
            <div v-if="d.tercentang.length" class="mb-2 hstack gap-1 justify-content-end">
              <button class="btn btn-sm btn-primary shadow-sm"
                @click="jenisSiswaTerpilih = 'multiple'; selectedRombel = d;" data-bs-toggle="modal"
                data-bs-target="#pindahkanAnggota">Pindahkan siswa terpilih</button>
              <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected(d)">Hapus data
                terpilih</button>
            </div>
            <div v-if="!d.anggotaRombel" class="spinner-border text-info text-center" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div v-else class="table-responsive shadow">
              <table class="table table-sm mb-0">
                <thead class="table-secondary align-middle">
                  <tr>
                    <th>No</th>
                    <th>NIS</th>
                    <th>Nama</th>
                    <th>Status</th>
                    <th>Agama</th>
                    <th>JK</th>
                    <th>
                      <div class="d-flex justify-content-between">
                        Tindakan
                        <input class="form-check-input" type="checkbox" v-model="d.semuaTercentang"
                          @click="centangSemua(d)">
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="d.anggotaRombel.length" class="small">
                  <tr v-for="a, index in d.anggotaRombel" :key="a">
                    <td>{{ index+=1 }}</td>
                    <td>{{ a.nis }}</td>
                    <td>{{ a.nama }}</td>
                    <td>{{ a.singkatan }}</td>
                    <td>{{ a.nama_agama }}</td>
                    <td>{{ a.jenis_kelamin }}</td>
                    <td>
                      <div class="d-flex justify-content-between">
                        <div class="hstack gap-1 me-1">
                          <button class="btn btn-sm btn-outline-primary d-flex"
                            @click="selectedSiswa = a; jenisSiswaTerpilih = 'single'; selectedRombel = d;"
                            data-bs-toggle="modal" data-bs-target="#pindahkanAnggota" v-tooltip="'Pindahkan'">
                            <svg xmlns="http://www.w3.org/2000/svg" height="13" fill="currentColor"
                              class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                          </button>
                          <div class="vr"></div>
                          <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(a)"
                            v-tooltip="'Hapus'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor"
                              class="bi bi-trash-fill" viewBox="0 0 16 16">
                              <path
                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                          </button>
                        </div>
                        <input class="form-check-input" type="checkbox" v-model="a.centang" @click="centangSatu(d, a)">
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center fst-italic py-3" colspan="7">Data tidak ditemukan.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="bg-light py-5 vl-parent" ref="accordion">
      <h4 class="text-muted m-0 text-center">Data tidak ditemukan.</h4>
    </div>
    <span v-if="dataRombel.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataRombel.length }} dari total {{ count }} data
    </span>
  </div>
  <div v-if="dataRombel.length" class="card-footer">
    <div class="row row-cols-auto justify-content-sm-between justify-content-center">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
              @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCount " :key="p">
            <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
              @click="pageCurrent = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrent = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
          (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
          (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
              @click="pageCurrent = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
              @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal fade" id="tambahAnggota" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    area-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formAssign">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Anggota Rombel {{ selectedRombel.nama }}</legend>
            <button type="button" id="tutupFormAssign" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <v-select multiple :options="siswaNonRombel" :placeholder="'Pilih Siswa'" label="nisNama"
              v-model="selectedRombel.siswaToAssign">
              <template #no-options>
                Data tidak ditemukan.
              </template>
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!selectedRombel.siswaToAssign.length" v-bind="attributes"
                  v-on="events">
              </template>
            </v-select>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="pindahkanAnggota" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    area-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formForward">
        <form @submit.prevent="pindahkanAnggota">
          <div class="modal-header">
            <legend class="modal-title">Pindahkan Siswa</legend>
            <button type="button" id="tutupFormForward" class="btn-close" data-bs-dismiss="modal"
              @click="rombelTujuan = null"></button>
          </div>
          <div class="modal-body">
            <ul v-if="jenisSiswaTerpilih == 'multiple'" class="list-group list-group-flush mb-3">
              <li v-for="a in selectedRombel.tercentang" :key="a" class="list-group-item p-0 fw-bold">{{ a.nis }} - {{
          a.nama }}
              </li>
            </ul>
            <ul v-else class="list-group list-group-flush mb-3">
              <li class="list-group-item p-0">{{ selectedSiswa.nis }} - {{ selectedSiswa.nama }}</li>
            </ul>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label">Rombel Awal</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control form-control-sm" :value="selectedRombel.nama">
              </div>
            </div>
            <div class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Rombel Tujuan</label>
              <div class="col-sm-9">
                <v-select :options="listRombel" label="nama" :placeholder="'Pilih Rombel Tujuan'" :selectOnTab="true"
                  :clearable="false" v-model="rombelTujuan"
                  :selectable="(option) => option.rombel_id !== selectedRombel.rombel_id">
                  <template #no-options>
                    Rombel tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!rombelTujuan" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Pindahkan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="nextSemester" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    area-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formForward">
        <form @submit.prevent="lanjutSemester">
          <div class="modal-header">
            <legend class="modal-title">Kenaikan Kelas (Semester)/Kelulusan</legend>
            <button type="button" id="tutupFormNext" class="btn-close" data-bs-dismiss="modal"
              @click="toSemester = toNextRombel = null"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Dari Rombel</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control form-control-sm" :value="fromRombel.nama">
              </div>
            </div>
            <div :class="jenisMigrasi != 2 ? 'row' : 'row mb-3'">
              <label class="col-sm-3 col-form-label col-form-label-sm">Jenis Lanjut</label>
              <div class="col-sm-9">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="jenis_migrasi" v-model="jenisMigrasi" value="2"
                    id="lanjut">
                  <label class="form-check-label" for="lanjut">
                    Lanjut Semester/Naik Kelas
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="jenis_migrasi" v-model="jenisMigrasi" value="1"
                    id="lulus">
                  <label class="form-check-label" for="lulus">
                    Lulus
                  </label>
                </div>
              </div>
            </div>
            <div v-if="jenisMigrasi == 2" class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Ke Semester</label>
              <div class="col-sm-9">
                <v-select :options="listSemester" label="nama" :placeholder="'Pilih Semester'" :selectOnTab="true"
                  :clearable="false" v-model="toSemester"
                  :selectable="(option) => option.semester_id !== this.semester.semester_id">
                  <template #no-options>
                    Semester tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!toSemester" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
            <div v-if="jenisMigrasi == 2" class="row">
              <label class="col-sm-3 col-form-label col-form-label-sm">Ke Rombel</label>
              <div class="col-sm-9">
                <v-select :options="listNextRombel" label="nama" :placeholder="'Pilih Rombel Tujuan'"
                  :selectOnTab="true" :clearable="false" v-model="toNextRombel"
                  :selectable="(option) => option.rombel_id !== this.fromRombel.rombel_id">
                  <template #no-options>
                    Rombel tidak ditemukan.
                  </template>
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!toNextRombel" v-bind="attributes" v-on="events">
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="!jenisMigrasi">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'AnggotaRombel',
  data() {
    return {
      assignOrDeleteListener: (d) => {
        const data = JSON.parse(d);
        this.dataRombel.every((r) => {
          if (r.rombel_id == data.rombel_id) {
            this.getDataAnggotaRombel(r);
            return false;
          }
          return true;
        })
        if (this.semester.semester_id == data.semester_id) {
          this.getSiswaNonRombel();
        }
      },
      moveListener: (d) => {
        const data = JSON.parse(d);
        this.dataRombel.forEach((r) => {
          if (r.rombel_id == data.rombel_id) {
            this.getDataAnggotaRombel(r);
          } else if (data.listSemesterRombelId && data.listSemesterRombelId.some((sr) => {
            return r.rombel_id == sr.rombel_id;
          })) {
            this.getDataAnggotaRombel(r);
          }
        })
      },
      multipleDeleteListener: (d) => {
        const data = JSON.parse(d);
        this.dataRombel.forEach((r) => {
          if (data.listSemesterRombelId.some((sr) => {
            return r.rombel_id == sr.rombel_id;
          })) {
            this.getDataAnggotaRombel(r);
          }
        })
      },
      semesterListener: () => {
        this.getListSemester();
        document.getElementById('tutupFormAssign').click();
      },
      rombelListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id && data.semester_id == this.semester.semester_id) {
          if (this.pageCurrent == this.pageCount && this.dataRombel.length == 1) {
            if (this.pageCurrent == 1) {
              this.getJumlahAnggotaRombel();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getJumlahAnggotaRombel();
          }
        } else if (data.listSemesterId &&
          data.listSemesterId.some((s) => {
            return s.semester_id == this.semester.semester_id;
          })
        ) {
          if (this.pageCurrent == this.pageCount && this.dataRombel.length <= data.listRombelId.length) {
            if (this.pageCurrent == 1) {
              this.getJumlahAnggotaRombel();
            } else {
              this.pageCurrent = 1;
            }
          } else {
            this.getJumlahAnggotaRombel();
          }
        }
        document.getElementById('tutupFormAssign').click();
        document.getElementById('tutupFormForward').click();
      },
      siswaListener: (d) => {
        this.getSiswaNonRombel();
        if (Number.isInteger(d)) {
          for (const r of this.dataRombel) {
            if (r.anggotaRombel) {
              for (const ar of r.anggotaRombel) {
                if (ar.siswa_id == d) {
                  this.getDataAnggotaRombel(r);
                  break;
                }
              }
              break;
            }
          }
        }
        if (d instanceof Array) {
          for (const r of this.dataRombel) {
            if (r.anggotaRombel) {
              for (const ar of r.anggotaRombel) {
                if (d.includes(ar.siswa_id)) {
                  this.getDataAnggotaRombel(r);
                  break;
                }
              }
            }
          }
        }
      },
      dataRombel: [],
      siswaNonRombel: [],
      listRombel: [],
      listSemester: [],
      semester: {},
      arrayLimit: [10, 25, 50, 100],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      selectedRombel: {
        siswaToAssign: []
      },
      selectedSiswa: {},
      jenisSiswaTerpilih: null,
      rombelTujuan: null,
      listNextRombel: [],
      jenisMigrasi: null,
      fromRombel: {},
      toSemester: null,
      toNextRombel: null
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getJumlahAnggotaRombel();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getJumlahAnggotaRombel();
      } else {
        this.pageCurrent = 1;
      }
    },
    semester() {
      if (this.pageCurrent == 1) {
        this.getJumlahAnggotaRombel();
        this.getListRombel();
        this.getSiswaNonRombel();
      } else {
        this.pageCurrent = 1;
      }
    },
    toSemester(newValue) {
      if (newValue) {
        this.getListNextRombel();
      } else {
        this.listNextRombel = [];
      }
    }
  },
  mounted() {
    this.getListSemester();
    WebSocketService.on("assigned-anggota-rombel", this.assignOrDeleteListener);
    WebSocketService.on("moved-anggota-rombel", this.moveListener);
    WebSocketService.on("deleted-anggota-rombel", this.assignOrDeleteListener);
    WebSocketService.on("deleted-multiple-anggota-rombel", this.multipleDeleteListener);
    WebSocketService.on("graduated-anggota-rombel", this.moveListener);
    WebSocketService.on("inserted-semester", this.semesterListener);
    WebSocketService.on("updated-semester", this.semesterListener);
    WebSocketService.on("deleted-semester", this.semesterListener);
    WebSocketService.on("deleted-multiple-semester", this.semesterListener);
    WebSocketService.on("inserted-rombel", this.rombelListener);
    WebSocketService.on("updated-rombel", this.rombelListener);
    WebSocketService.on("deleted-rombel", this.rombelListener);
    WebSocketService.on("deleted-multiple-rombel", this.rombelListener);
    WebSocketService.on("inserted-siswa", this.siswaListener);
    WebSocketService.on("updated-siswa", this.siswaListener);
    WebSocketService.on("deleted-siswa", this.siswaListener);
  },
  beforeUnmount() {
    WebSocketService.off("assigned-anggota-rombel", this.assignOrDeleteListener);
    WebSocketService.off("moved-anggota-rombel", this.moveListener);
    WebSocketService.off("deleted-anggota-rombel", this.assignOrDeleteListener);
    WebSocketService.off("deleted-multiple-anggota-rombel", this.multipleDeleteListener);
    WebSocketService.off("graduated-anggota-rombel", this.moveListener);
    WebSocketService.off("inserted-semester", this.semesterListener);
    WebSocketService.off("updated-semester", this.semesterListener);
    WebSocketService.off("deleted-semester", this.semesterListener);
    WebSocketService.off("deleted-multiple-semester", this.semesterListener);
    WebSocketService.off("inserted-rombel", this.rombelListener);
    WebSocketService.off("updated-rombel", this.rombelListener);
    WebSocketService.off("deleted-rombel", this.rombelListener);
    WebSocketService.off("deleted-multiple-rombel", this.rombelListener);
    WebSocketService.off("inserted-siswa", this.siswaListener);
    WebSocketService.off("updated-siswa", this.siswaListener);
    WebSocketService.off("deleted-siswa", this.siswaListener);
  },
  methods: {
    async getListSemester() {
      try {
        const response = await this.axios.post('/kurikulum/semester/list', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listSemester = response.data.dataSemester;
          this.semester = this.listSemester.filter((s) => s.periode_aktif == 1)[0];
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListRombel() {
      try {
        const response = await this.axios.post('/kurikulum/rombel/list', {
          semester_id: this.semester.semester_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listRombel = response.data.dataRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListNextRombel() {
      try {
        const response = await this.axios.post('/kurikulum/rombel/list', {
          semester_id: this.toSemester.semester_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.listNextRombel = response.data.dataRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getSiswaNonRombel() {
      try {
        const response = await this.axios.post('/kesiswaan/siswa/list-non-rombel', {
          semester_id: this.semester.semester_id,
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.siswaNonRombel = response.data.siswaNonRombel;
          for (const s of this.siswaNonRombel) {
            s.nisNama = s.nis + ' - ' + s.nama
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJumlahAnggotaRombel() {
      let memuat = this.$loading.show({
        container: this.$refs.accordion,
        loader: 'dots'
      });
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel/jumlah', {
          semester_id: this.semester.semester_id,
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.dataRombel = response.data.rombel;
          for (const r of this.dataRombel) {
            r.tercentang = [];
            r.semuaTercentang = false;
            r.siswaToAssign = [];
          }
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getDataAnggotaRombel(d) {
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel', {
          rombel_id: d.rombel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          d.anggotaRombel = response.data.anggotaRombel;
          d.jumlah = d.anggotaRombel.length
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const newData = {
        semester_id: this.semester.semester_id,
        rombel_id: this.selectedRombel.rombel_id,
        listSiswaId: this.selectedRombel.siswaToAssign.map(s => s.siswa_id)
      }
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel/masukkan', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormAssign').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.selectedRombel.siswaToAssign = []
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async pindahkanAnggota() {
      let memuat = this.$loading.show({
        container: this.$refs.formForward,
        loader: 'spinner'
      });
      const listAnggotaRombelId = this.jenisSiswaTerpilih == 'single' ? [this.selectedSiswa.anggota_rombel_id] : this.selectedRombel.tercentang.map(a => a.anggota_rombel_id);
      const newData = {
        new_rombel_id: this.rombelTujuan.rombel_id,
        listAnggotaRombelId: listAnggotaRombelId
      }
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel/pindahkan', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormForward').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.selectedRombel.tercentang = [];
            this.selectedRombel.semuaTercentang = false;
            this.selectedSiswa = {};
            this.jenisSiswaTerpilih = null;
            this.rombelTujuan = null;
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async lanjutSemester() {
      let memuat = this.$loading.show({
        container: this.$refs.formNext,
        loader: 'spinner'
      });
      const newData = this.jenisMigrasi == 1 ? {
        rombel_id: this.fromRombel.rombel_id
      } : {
        from_rombel_id: this.fromRombel.rombel_id,
        to_semester_id: this.toSemester.semester_id,
        to_rombel_id: this.toNextRombel.rombel_id
      }
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel/naik-semester', newData, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          document.getElementById('tutupFormNext').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.jenisMigrasi = null;
            this.toSemester = null;
            this.toNextRombel = null;
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDelete(anggota) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus anggota rombel <code class="text-black text-bg-secondary bg-opacity-50">${anggota.nis + ' - ' + anggota.nama}</code>?</span><br/>
            <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(anggota);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/kurikulum/anggota-rombel/hapus', {
          anggota_rombel_id: d.anggota_rombel_id
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDeleteSelected(rombel) {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus anggota rombel terpilih?<br/>&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected(rombel)
        }
      });
    },
    async deleteSelected(r) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post("/kurikulum/anggota-rombel/hapus/multiple", {
          dataToDelete: r.tercentang.map(a => a.anggota_rombel_id),
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            r.tercentang = [];
            r.semuaTercentang = false;
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(rombel, anggota) {
      const ditemukan = rombel.tercentang.findIndex((a) =>
        JSON.stringify(a) == JSON.stringify(anggota)
      );
      if (ditemukan >= 0) {
        rombel.tercentang.splice(ditemukan, 1);
      } else {
        rombel.tercentang.push(anggota);
      }
      if (rombel.tercentang.length == rombel.anggotaRombel.length) {
        rombel.semuaTercentang = true;
      } else {
        rombel.semuaTercentang = false;
      }
    },
    centangSemua(rombel) {
      rombel.tercentang = []
      if (rombel.semuaTercentang) {
        for (const d of rombel.anggotaRombel) {
          d.centang = false;
          rombel.tercentang = [];
        }
      } else {
        for (const d of rombel.anggotaRombel) {
          d.centang = true;
          rombel.tercentang.push(d);
        }
      }
    },
  }
}
</script>

<style></style>