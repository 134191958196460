<template>
  <div class="fst-italic">Mengautentikasi...</div>
</template>

<script>
export default {
  name: 'SetToken',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style></style>