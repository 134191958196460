<template>
  <nav class="navbar navbar-expand-md py-1 position-sticky top-0" data-bs-theme="dark">
    <div class="container-fluid">
      <router-link class="navbar-brand fs-5" to="/" @click="tutupNavBar">
        <img src="../assets/logo.png" class="d-inline-block align-text-bottom" alt="Logo" height="24">
        Admin
      </router-link>
      <button id="toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu"
        aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="menu">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link" :active-class="'active'" @click="tutupNavBar">Beranda</router-link>
          </li>
          <li class="nav-item" v-if="adminPengguna">
            <router-link to="/users" class="nav-link" :class="{ 'active': $route.name == 'users' }"
              @click="tutupNavBar">Pengguna</router-link>
          </li>
          <li class="nav-item" v-if="kurikulum">
            <router-link to="/kurikulum" class="nav-link" :class="{ 'active': $route.name == 'kurikulum' }"
              @click="tutupNavBar">Kurikulum</router-link>
          </li>
          <li class="nav-item" v-if="kesiswaan">
            <router-link to="/kesiswaan" class="nav-link" :class="{ 'active': $route.name == 'kesiswaan' }"
              @click="tutupNavBar">Kesiswaan</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shorts" class="nav-link" :active-class="'active'" @click="tutupNavBar">URL
              Shortener</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/tamu" class="nav-link" :active-class="'active'" @click="tutupNavBar">Buku
              Tamu</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              title="nama pengguna">
              <img v-if="user.foto" :src="user.foto" height="24" alt="" class="rounded-circle"
                :class="{ 'border border-light': $route.name == 'ganti-sandi' || $route.name == 'profil' }">
              <img v-else src="../assets/person-light.png" height="24" alt="">
            </a>
            <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end shadow-lg">
              <li>
                <div class="dropdown-header text-center py-0">
                  <img v-if="user.foto" :src="user.foto" height="72" alt="" class="rounded-circle">
                  <img v-else src="../assets/person-light.png" height="72" alt="">
                  <h6 class="h6 text-light mb-0">{{ user.nama }}</h6>
                  <span class="fst-italic small text-light opacity-50">{{ user.username }}</span>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link to="/profil" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Profil</router-link>
              </li>
              <li>
                <router-link to="/ganti-sandi" class="dropdown-item" :active-class="'active'" @click="tutupNavBar">Ganti
                  Kata Sandi</router-link>
              </li>
              <li><a class="dropdown-item btn" @click="logout">Keluar</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      kesiswaan: false,
      kurikulum: false,
      adminPengguna: false,
    }
  },
  props: {
    user: Object
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
  },
  mounted() {
    this.kesiswaan = String(this.user.ket).split(",").some((k) => {
      return ["it", "kesiswaan"].includes(k);
    });
    this.kurikulum = String(this.user.ket).split(",").some((k) => {
      return ["it", "kurikulum"].includes(k);
    });
    this.adminPengguna = String(this.user.ket).split(",").some((k) => {
      return ["it", "admin-pengguna"].includes(k);
    });
  },
  methods: {
    async logout() {
      try {
        const response = await this.axios.post(this.$auth + '/logout', {},
          { headers: { Authorization: this.token } });
        if (response) {
          this.$store.dispatch('logoutUser');
          window.location.href = this.$sso + "/" + this.$appId;
        }
      } catch (err) {
        this.$store.dispatch('logoutUser');
        window.location.href = this.$sso + '/' + this.$appId
      }
    },
    tutupNavBar() {
      const menu = document.getElementById('menu');
      const toggler = document.getElementById('toggler');
      if (menu.getAttribute('class') == 'navbar-collapse collapse show' && toggler.getAttribute('class') == 'navbar-toggler') {
        toggler.click();
      }
    }
  }
}
</script>

<style scoped>
nav,
.dropdown-menu {
  background-color: #184b89;
}
</style>