<template>
  <h4>Manajemen Akun Pengguna</h4>
  <div class="card shadow vl-parent" ref="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item">
          <router-link to="/users/admin" class="nav-link" active-class="active">Admin
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/users/guru" class="nav-link" active-class="active">Guru
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/users/siswa" class="nav-link" active-class="active">Siswa
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="!$route.params.role">
      <div class="card-body">
        <h5>Data Pengguna Aplikasi</h5>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Admin</span>
                <router-link to="/users/admin" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Admin'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="admin.jumlah">{{ admin.jumlah }}</span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">pengguna</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Guru</span>
                <router-link to="/users/guru" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Guru'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="guru.jumlah">{{ guru.jumlah }}</span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">pengguna</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Siswa</span>
                <router-link to="/users/siswa" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Akun Siswa'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="siswa.jumlah">{{ siswa.jumlah }}</span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">pengguna</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <user-admin v-else-if="$route.params.role === 'admin'" />
    <user-guru v-else-if="$route.params.role === 'guru'" />
    <user-siswa v-else-if="$route.params.role === 'siswa'" />
    <not-found v-else />
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import UserAdmin from '@/components/UserAdmin.vue';
import UserGuru from '@/components/UserGuru.vue';
import UserSiswa from '@/components/UserSiswa.vue';
import NotFound from '@/components/NotFound.vue';
export default {
  name: 'UserView',
  components: { UserAdmin, UserGuru, UserSiswa, NotFound },
  data() {
    return {
      insertListener: (d) => {
        const insert = JSON.parse(d);
        switch (insert.role) {
          case "admin":
            this.admin.jumlah += insert.count;
            break;
          case "guru":
            this.guru.jumlah += insert.count;
            break;
          case "siswa":
            this.siswa.jumlah += insert.count;
            break;
        }
      },
      deleteListener: (d) => {
        const del = JSON.parse(d);
        switch (del.role) {
          case "admin":
            this.admin.jumlah -= del.count;
            break;
          case "guru":
            this.guru.jumlah -= del.count;
            break;
          case "siswa":
            this.siswa.jumlah -= del.count;
            break;
        }
      },
      users: [],
      admin: { jumlah: 0 },
      guru: { jumlah: 0 },
      siswa: { jumlah: 0 }
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      container: this.$refs.card,
      loader: 'spinner'
    });
    this.getJumlahUser();
    setTimeout(() => {
      memuat.hide();
    }, 250);
    WebSocketService.on("inserted-user", this.insertListener);
    WebSocketService.on("deleted-user", this.deleteListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-user", this.insertListener);
    WebSocketService.off("deleted-user", this.deleteListener);
  },
  methods: {
    async getJumlahUser() {
      try {
        const response = await this.axios.post('/user/jumlah', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data.users) {
          this.users = response.data.users;
          this.admin = this.users.filter((d) => d.role === 'admin')[0] ? this.users.filter((d) => d.role === 'admin')[0] : { jumlah: 0 };
          this.guru = this.users.filter((d) => d.role === 'guru')[0] ? this.users.filter((d) => d.role === 'guru')[0] : { jumlah: 0 };
          this.siswa = this.users.filter((d) => d.role === 'siswa')[0] ? this.users.filter((d) => d.role === 'siswa')[0] : { jumlah: 0 };
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>